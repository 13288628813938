import React from 'react';
import PropTypes from 'prop-types';
import styles from './searchInput.module.css';

const SearchItemRegion = ({ item, onSelect }) => {
  const onClick = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    onSelect({
      ...item,
      type: 'vertex',
      label: '',
      metroRegion: item.metroRegion,
      id: '',
    });
  };

  return (
    <div className={styles.search_item} onClick={onClick}>
      <div className={styles.search_item_icon}></div>
      <div className={styles.search_item_value}>{item.metroRegion}</div>
    </div>
  );
};

SearchItemRegion.propTypes = {
  // id, metroRegion, displayValue
  item: PropTypes.object,
  onSelect: PropTypes.func,
};

export default SearchItemRegion;
