import React from 'react';
import PropTypes from 'prop-types';
import { BsChevronRight } from 'react-icons/bs';
import CardHeader from '../../card-header';
import Img from '../../img-container';
import styles from './education.module.css';
import ButtonSectionCard from '../../button-section-card';
import { useDispatch, useSelector } from 'react-redux';
import { setSearch } from 'store/graph/actions';
import ReactTooltip from 'react-tooltip';
import { EducationIcon } from 'common/icon/icon';
import { selectIsContextIdsEmpty } from 'store/graph/selectors';
import LastUpdated from 'common/last-updated';

const EducationCard = ({ education }) => {
  const {
    name,
    institution,
    college,
    imgSrc,
    department,
    major,
    region,
    nodeKey,
    ingestionDateUtc,
  } = education;
  const dispatch = useDispatch();
  const showShortestPath = useSelector(selectIsContextIdsEmpty);
  const setAsAContext = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(
      setSearch({
        displayValue: name,
        id: nodeKey,
        metroRegion: region,
        label: 'fund',
        type: 'vertex',
        value: name,
      }),
    );
  };

  return (
    <div className={styles.card}>
      <CardHeader
        value="Education"
        showShortestPath={!showShortestPath}
        icon={<EducationIcon className={styles.education_icon} />}
        nodeId={nodeKey}
        properties={education}
      />
      <div className={styles.content}>
        <div className={styles.content_header}>
          <div className={styles.left_section}>
            <div className={styles.name}>
              <h2 className={styles.name_desc}>{name}</h2>
              <BsChevronRight
                data-tip
                data-for={nodeKey}
                className={styles.name_icon}
                onClick={setAsAContext}
              />
              <ReactTooltip id={nodeKey} place="top" effect="solid">
                Add as a search context
              </ReactTooltip>
            </div>
            <div className={styles.person_details_wrapper}>
              <div className={styles.person_details_title}>Institution:</div>
              <div className={styles.person_details_value}>{institution}</div>
            </div>
            <div className={styles.person_details_wrapper}>
              <div className={styles.person_details_title}>College:</div>
              <div className={styles.person_details_value}>{college}</div>
            </div>
            <div className={styles.person_details_wrapper}>
              <div className={styles.person_details_title}>Department:</div>
              <div className={styles.person_details_value}>{department}</div>
            </div>
            <div className={styles.person_details_wrapper}>
              <div className={styles.person_details_title}>Major:</div>
              <div className={styles.person_details_value}>{major}</div>
            </div>
          </div>
          <div className={styles.right_section}>
            <Img src={imgSrc} size="64px" />
          </div>
        </div>
        <LastUpdated ingestionDateUtc={ingestionDateUtc} />
      </div>
      <ButtonSectionCard
        icon={<BsChevronRight className={styles.button_right_icon} />}
        text="See connections"
        hideAll={true}
        showTed={false}
      />
    </div>
  );
};

EducationCard.propTypes = {
  education: PropTypes.object,
};

export default EducationCard;
