import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Loading from 'common/chart/loading';
import Relationship from 'common/edge-cards';
import ReGraphChartWrapper from 'common/chart';
import FilterCards from 'common/edge-filter-cards';
import PersonDetailsCard from 'common/node-details-cards/person';
import OrganizationDetailsCard from 'common/node-details-cards/organization';
import {
  selectIsLoading,
  selectSelectedEdge,
  selectSelectedVertex,
} from 'store/graph/selectors';
import { nodeType } from 'utils/constants';
import styles from './home.module.css';
import { FeedbackModel } from 'common/feeback_modal';
import { selectShowFeedbackModal } from 'store/core/selectors';
import { Sidebar } from 'common/sidebar';

const Home = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const selectedDetailedNode = useSelector(selectSelectedVertex);
  const selectedEdge = useSelector(selectSelectedEdge);
  const showFeedbackModal = useSelector(selectShowFeedbackModal);
  const isLoading = useSelector(selectIsLoading);

  return (
    <div className={styles.root}>
      <Sidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
      <div
        className={`${styles.right_section} ${
          isCollapsed && styles.full_width
        }`}
      >
        <div className={styles.filter_card_wrapper}>
          <FilterCards />
        </div>
        {selectedEdge && (
          <div className={styles.detailed_wrapper}>
            <Relationship selectedEdge={selectedEdge} />
          </div>
        )}
        {selectedDetailedNode && (
          <div className={styles.detailed_wrapper}>
            {selectedDetailedNode.nodeType === nodeType.Person && (
              <PersonDetailsCard person={selectedDetailedNode} />
            )}
            {selectedDetailedNode.nodeType === nodeType.Organization && (
              <OrganizationDetailsCard properties={selectedDetailedNode} />
            )}
          </div>
        )}
        {!isLoading && <ReGraphChartWrapper />}
        {isLoading && <Loading />}
      </div>
      {showFeedbackModal && <FeedbackModel />}
    </div>
  );
};

Home.propTypes = {
  connection: PropTypes.object, // the signalR connection object. uUse this to make requests to get data
};

export default Home;
