import React from 'react';
import PropTypes from 'prop-types';
import { GiPathDistance } from 'react-icons/gi';
import styles from './cardHeader.module.css';
import { useDispatch } from 'react-redux';
import { addHiddenNode, setShortestPathNodeId } from 'store/filters/actions';
import ReactTooltip from 'react-tooltip';
import { PersonIcon } from 'common/icon/icon';

const CardHeader = ({
  nodeId,
  value,
  hideIcon,
  icon = <PersonIcon className={styles.cardHeaderIcon} />,
  showShortestPath = false,
  properties,
}) => {
  const dispatch = useDispatch();
  const handleShortestPath = () => {
    dispatch(setShortestPathNodeId(nodeId));
  };
  const hideNode = () => {
    dispatch(addHiddenNode({ nodeId, name: properties.name }));
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.text}>
        {!hideIcon && icon}
        <p className={styles.value}>{value}</p>
      </div>
      <div className={styles.hide} onClick={hideNode}>
        Hide
      </div>
      {showShortestPath && (
        <div className={styles.distance_wrapper}>
          <GiPathDistance
            data-tip
            data-for={value}
            className={styles.three_dots}
            onClick={handleShortestPath}
          />
          <ReactTooltip id={value} place="top" effect="solid">
            Show Path
          </ReactTooltip>
        </div>
      )}
    </div>
  );
};

CardHeader.propTypes = {
  value: PropTypes.string,
  nodeId: PropTypes.string.isRequired,
};

export default CardHeader;
