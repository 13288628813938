import React from "react";
import PropTypes from 'prop-types';
import { BsChevronRight } from 'react-icons/bs';
import CardHeader from "../../card-header";
import Img from '../../img-container';
import Input from '../../input';
import styles from '../styles/employer.module.css';
import { ProfessionalIcon } from "common/icon/icon";

const EmployerCard = ({ properties, headerText }) => {
    const { website, fullname, imgSrc = '', industry, organizationType = '', constituency = '' } = properties;
    return (
        <div className={styles.card}>
            <CardHeader value={headerText} icon={<ProfessionalIcon className={styles.cardHeaderIcon} />}/>
            <div className={styles.content}>
                <div className={styles.content_header}>
                    <div className={styles.left_section}>
                        <div className={styles.name}>
                            <h2 className={styles.name_desc}>{fullname}</h2>
                            <BsChevronRight className={styles.name_icon} />
                        </div>
                        <div className={styles.person_details_wrapper}>
                            <div className={styles.person_details_title}>Website:</div>
                            <div className={styles.person_details_value}>{website}</div>
                        </div>
                    </div>
                    <div className={styles.right_section}>
                        <Img src={imgSrc} size="64px" />
                    </div>
                </div>
                <div className={styles.giving_prospect_status}>
                    <div className={styles.split_wrapper}>
                        <Input value={organizationType} label="Organization type" />
                    </div>
                    <div className={styles.split_wrapper}>
                        <Input value={industry} label="Industry" />
                    </div>
                </div>
                <div className={styles.affiliation_unit}>
                    <Input value={constituency} label="Constituency type" />
                </div>
            </div>
        </div>
    );
}

EmployerCard.propTypes = {
    total_giving: PropTypes.string,
    affiliation_unit: PropTypes.string,
    prospect_status: PropTypes.object,
}

export default EmployerCard;