export const createQuery = (contexts, filters, traversals) => {
  const query = {
    context: contexts,
    filters: [
      {
        type: 'edge',
        label: '',
        direction: 'both',
        filterValue: null,
        condition: 'none',
        filters: [],
      },
    ],
    traversals,
  };
  return query;
};

export const getNextMinTraversal = (contextsMap, contextToIgnore) => {
  let min = 3;
  for (const contextId in contextsMap) {
    if (contextsMap[contextId] < min) {
      min = contextsMap[contextId];
    }
  }

  return min;
};
