import React, { useState } from 'react';
import DropDown from '../../drop-down';
import styles from './prospect.module.css'
import Item from './item';
import { useSelector } from 'react-redux';
import { selectProspectStatus } from 'store/filters/selectors';

const ProspectFilter = () => {
  const [open, setOpen] = useState(false);
  const prospects = useSelector(selectProspectStatus);
  const handleToggle = () => {
    setOpen(!open);        
  }

  return (
    <div className={!open ? 'filter_by_wrapper' : 'filter_by_open_wrapper'}>
      <DropDown filterText="Prospect status" onClick={handleToggle} open={open} />
      {open && 
      <div className={styles.filter_grid}>
        {Object.keys(prospects).map(x => {
           const filter = prospects[x];
           return (<Item key={x} prospectKey={x} value={filter.text} isSelected={filter.isSelected} />);
          }
        )}
      </div>
      }
    </div>
  );
}

export default ProspectFilter;