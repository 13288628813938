import { put, call, takeLatest } from 'redux-saga/effects';
import { setToken, setupConnection } from 'store/core/actions';
import { setError, setIsLoading } from './actions';
import { SET_LOGIN } from './constants';

const getToken = async (username, pass) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/api/token`,
      {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `username=${username}&password=${pass}&grant_type=password`,
      },
    );
    let json = await response.json();
    const expires_in = json.expires_in + Date.now();
    const access_token = json.access_token;
    return { access_token, expires_in };
  } catch (e) {}
  return null;
};

function* token(action) {
  yield put(setIsLoading(true));
  const response = yield call(getToken, action.username, action.pass);
  if (response) {
    const { access_token, expires_in } = response;
    if (access_token) {
      yield put(setToken(access_token, expires_in, action.username));
      yield put(setupConnection(access_token));
    } else {
      yield put(
        setError(
          'Invalid email or password when attempting to login. Please try again!',
        ),
      );
      yield put(setIsLoading(false));
    }
  } else {
    yield put(
      setError(
        'Invalid email or password when attempting to login. Please try again!',
      ),
    );
    yield put(setIsLoading(false));
  }
}

function* loginSaga() {
  yield takeLatest(SET_LOGIN, token);
}

export default loginSaga;
