import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToggleShowDeceased } from 'store/filters/actions';
import { selectShowDeceasedConnections } from 'store/filters/selectors';
import styles from './deceased.module.css';

const ShowDeceasedFilter = () => {
  const show = useSelector(selectShowDeceasedConnections);
  const dispatch = useDispatch();
  const toggle = () => {
    dispatch(setToggleShowDeceased(!show));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.drop_down}>
        <div className={styles.name}>Show Deceased</div>
        <Switch isOn={show} onColor="#38b2d9" handleToggle={toggle} />
      </div>
    </div>
  );
};

const Switch = ({ isOn, handleToggle, onColor }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className={styles['react-switch-checkbox']}
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        style={{ background: isOn && onColor }}
        className={styles['react-switch-label']}
        htmlFor={`react-switch-new`}
      >
        <span className={styles[`react-switch-button`]} />
      </label>
    </>
  );
};

export default ShowDeceasedFilter;
