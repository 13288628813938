import React from 'react';
import { GiPresent } from 'react-icons/gi';
import { BsCheckCircleFill } from 'react-icons/bs';
import { MdCastForEducation } from 'react-icons/md';
import styles from './styles/types-bar.module.css';

const TypesBar = ({ showManaged, showDonor, showAlumni, iconColor='#303030'  }) => {
    return (<div className={styles.wrapper_bar}>
        {showManaged && 
            <div className={styles.icon_wrapper} style={{color: iconColor}}>
                <BsCheckCircleFill className={styles.check_icon} />
                <span className={styles.text}>Managed</span>
            </div>
        }
        {showAlumni && 
            <div className={styles.icon_wrapper} style={{color: iconColor}}>
                <MdCastForEducation className={styles.education_icon} />
                <span className={styles.text}>Alumni</span>
            </div>
        }
        {showDonor && 
            <div className={styles.icon_wrapper_last} style={{color: iconColor}}>
                <GiPresent className={styles.present_icon} />
                <span className={styles.text}>Donor</span>
            </div>
        }
    </div>);
};

export default TypesBar;