import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectToken } from "store/core/selectors";

const ProtectedRoute = ({ children }) => {
    const token = useSelector(selectToken);
    if (!token) {
      return <Navigate to="/login" replace />;
    }
    return children;
};

export default ProtectedRoute;