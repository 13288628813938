import { useState, useEffect, useRef } from 'react';

export default function useComponentVisible(
  initialIsVisible,
  callback = undefined,
) {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false);
      if (callback) {
        callback();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return { ref, isVisible, setIsVisible };
}

export function IdleTimer(dispatch) {
  const [idleTime, setIdleTime] = useState(0);

  useEffect(() => {
    const idleInterval = setInterval(timerIncrement, 60000); // 1 minute

    const resetIdleTime = () => {
      setIdleTime(0);
    };

    const handleMouseMove = () => {
      resetIdleTime();
    };

    const handleKeyPress = () => {
      resetIdleTime();
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('keypress', handleKeyPress);

    return () => {
      clearInterval(idleInterval);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  const timerIncrement = () => {
    setIdleTime(idleTime + 1);
    if (idleTime > 29) {
      // 30 minutes
      window.location.reload();
    }
  };

  return null; // or you can return some JSX if needed
}
