import React from 'react';
import { MdArrowBackIos, MdOutlineAddCircle } from 'react-icons/md';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FiExternalLink } from 'react-icons/fi';
import Input from '../../input';
import LinkedInput from '../../input/linked-input';
import ReactTooltip from 'react-tooltip';
import ButtonSectionCard from '../../button-section-card';
import Snapshot from '../snapshot';
// import MostConnected from "../connections/most-connected";
// import TopExecutives from "../connections/top-executives";
import styles from './styles/organization-details-card.module.css';
import Img from '../../img-container';
import { useDispatch } from 'react-redux';
import { setSelectedVertex } from 'store/graph/actions';
import TypesBar from 'common/types-bar';
import MostConnected from '../connections/most-connected';
import { ProfessionalIcon } from 'common/icon/icon';
import LastUpdated from 'common/last-updated';

const OrganizationDetailsCard = ({ properties }) => {
  const dispatch = useDispatch();
  const {
    name,
    imgSrc,
    website,
    organizationType,
    industry,
    constituencyType,
    alum,
    donor,
    activelymanaged,
    snapshot,
    tedUrl,
    recordKey,
    region,
    nodeKey,
    ingestionDateUtc,
  } = properties;

  const handleBack = () => {
    dispatch(setSelectedVertex(null));
  };

  return (
    <div className={styles.card}>
      <div className={styles.top_header}>
        <div className={styles.back_wrapper} onClick={handleBack}>
          <MdArrowBackIos className={styles.arrow_back} />
          <p className={styles.back_text}>Back</p>
        </div>
        <BsThreeDotsVertical className={styles.three_dots} />
      </div>
      <div className={styles.header_background}>
        <div className={styles.person_header}>
          <div className={styles.left_person_header_section}>
            <div className={styles.card_header_wrapper}>
              <ProfessionalIcon className={styles.cardHeaderIcon} />
              <p className={styles.card_header_value}>Organization</p>
            </div>
            <div data-tip data-for="registerTip" className={styles.name}>
              {name}
            </div>
            <ReactTooltip id="registerTip" place="top" effect="solid">
              {name}
            </ReactTooltip>
          </div>
          <div className={styles.right_person_header_section}>
            <Img src={imgSrc} size="64px" />
          </div>
        </div>
        <LastUpdated ingestionDateUtc={ingestionDateUtc} />
      </div>
      <div className={styles.organization_tags_wrapper}>
        <TypesBar
          showAlumni={alum === 1}
          showManaged={activelymanaged}
          showDonor={donor === 1}
        />
      </div>
      <div className={styles.content_wrapper}>
        <div className={styles.content}>
          <LinkedInput
            value={website}
            label="Website"
            icon={<FiExternalLink className={styles.button_right_icon} />}
          />
          <div className={styles.input_wrapper}>
            <Input value={region} label="Region" />
          </div>
          <div className={styles.two_section}>
            <div className={styles.left_two_section}>
              <Input value={organizationType} label="Organization type" />
            </div>
            <div className={styles.right_two_section}>
              <Input value={industry} label="Industry" />
            </div>
          </div>
          <div className={styles.input_wrapper}>
            <Input value={constituencyType} label="Constituency type" />
          </div>
        </div>
        <div className={styles.more_section}>
          <Snapshot snapshot={snapshot} titleColor="#97B912" />
          <div className={styles.connections_wrapper}>
            <div className={styles.connections_title}>Connections</div>
            {/* <TopExecutives /> */}
            <MostConnected label={recordKey} id={nodeKey} region={region} />
          </div>
        </div>
      </div>
      <ButtonSectionCard
        tedUrl={tedUrl}
        icon={<MdOutlineAddCircle className={styles.button_right_icon} />}
      />
    </div>
  );
};

export default OrganizationDetailsCard;
