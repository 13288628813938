import React from 'react';
import familyIcon from '../../images/icon-family.svg';
import charitableIcon from '../../images/icon-charitable.svg';
import education from '../../images/icon-education.svg';
import engagement from '../../images/icon-engagement.svg';
import organization from '../../images/icon-Organization.svg';
import professional from '../../images/icon-professional.svg';
import person from '../../images/icon-personal.svg';
import styles from './default_icon.module.css';

export const FamilyIcon = ({ className }) => {
  return (
    <div className={className}>
      <img src={familyIcon} alt='' />
    </div>
  );
}

export const PersonIcon = ({ className }) => {
  return (
    <div className={className}>
      <img src={person} alt='' />
    </div>
  );
}

export const PersonalIcon = ({ className }) => {
  return (
    <div className={className}>
      <img src={professional} alt='' />
    </div>
  );
}

export const ProfessionalIcon = ({ className }) => {
  return (
    <div className={className}>
      <img src={organization} alt='' />
    </div>
  );
}

export const EducationIcon = ({ className }) => {
  return (
    <div className={className}>
      <img src={education} alt='' />
    </div>
  );
}

export const EngagementIcon = ({ className = styles.engagement_icon, imgClassName = styles.engagement_icon_img }) => {
  return (
    <div className={className}>
      <img className={imgClassName} src={engagement} alt='' />
    </div>
  );
}

export const CharitableIcon = ({ className }) => {
  return (
    <div className={className}>
      <img src={charitableIcon} alt='' />
    </div>
  );
}