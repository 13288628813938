import React from "react";
import PropTypes from 'prop-types';
import styles from './input.module.css';

const SplitInput = ({ title, amount, label }) => {
    return (
        <div className={styles.wrapper}>
            {label && <label className={styles.input_label}>{label}</label>}
            <div className={styles.split_input}>
                <div className={styles.split_title}>{title}</div>
                <div className={styles.split_amount}>{amount}</div>
            </div>
        </div>
    );
}

SplitInput.propTypes = {
    label: PropTypes.string,
    title: PropTypes.string,
    amount: PropTypes.string,
}

export default SplitInput;