import { createSelector } from 'reselect';
import {
  selectEdgeFilters,
  selectEdgeFilterType,
  selectHiddenNodes,
  selectNodeFilterOut,
  selectNodeFilters,
  selectNodeFiltersOptions,
  selectNodeFilterType,
  selectRegionFilter,
  selectShortestPathNodeId,
  selectTimeFilters,
} from 'store/filters/selectors';
import { IsObjectEmpty } from 'utils/graph-helper';
import { selectItemsHelper } from './graph-items-selector-helper';

const graph = (state) => state.graph;

export const selectNodes = createSelector([graph], (state) => state.nodes);

export const selectNodeProperties = (nodeId) =>
  createSelector([graph], (state) => {
    return state.nodes[nodeId]?.node?.data;
  });

export const selectLinks = createSelector([graph], (state) => state.links);

export const selectContextIds = createSelector(
  [graph],
  (state) => state.contextIds,
);
export const selectIsContextIdsEmpty = createSelector([graph], (state) =>
  IsObjectEmpty(state.contextIds),
);
export const selectTopConnectionsLoading = createSelector(
  [graph],
  (state) => state.topConnectionsLoading,
);

export const selectTopConnections = createSelector([graph], (state) => {
  return state.topConnections[state.selectedVertex];
});

export const selectContexts = createSelector([graph], (state) => {
  const { contexts } = state;
  const arr = [];
  for (const contextId in contexts) {
    const context = contexts[contextId];
    arr.push(context.value);
  }
  return arr;
});

export const IsGraphEmpty = createSelector([graph], (state) =>
  IsObjectEmpty(state.contexts),
);

export const selectContextsForItems = createSelector([graph], (state) => {
  const { contexts } = state;
  return contexts;
});

export const selectItems = createSelector(
  [
    selectNodes,
    selectLinks,
    selectContextIds,
    selectEdgeFilters,
    selectEdgeFilterType,
    selectNodeFiltersOptions,
    selectNodeFilterType,
    selectRegionFilter,
    selectNodeFilters,
    selectNodeFilterOut,
    selectShortestPathNodeId,
    selectTimeFilters,
    selectContextsForItems,
    selectHiddenNodes,
  ],
  (
    nodes,
    links,
    contextIds,
    filtersForEdges,
    edgeFilterType,
    nodeOptions,
    nodeFilterType,
    regions,
    nodeFilters,
    nodeTypeFilterOut,
    shortestPathNodeId,
    timeFilters,
    contexts,
    hiddenNodes,
  ) => {
    return selectItemsHelper(
      nodes,
      links,
      contextIds,
      filtersForEdges,
      edgeFilterType,
      nodeOptions,
      nodeFilterType,
      regions,
      nodeFilters,
      nodeTypeFilterOut,
      shortestPathNodeId,
      timeFilters,
      contexts,
      hiddenNodes,
    );
  },
);

export const selectSelectedVertex = createSelector([graph], (state) => {
  const { nodes, selectedVertex } = state;
  return nodes[selectedVertex]?.node?.data;
});

export const selectSearchContext = createSelector(
  [graph],
  (state) => state.searchContext,
);

export const selectSelectedEdgeId = createSelector(
  [graph],
  (state) => state.selectedEdgeId,
);
// top node is always outNode, bottom node is inNode
// id1 is always inNode
export const selectSelectedEdge = createSelector([graph], (state) => {
  const { links, nodes, selectedEdgeId } = state;
  const edge = links[selectedEdgeId]?.link;
  if (edge) {
    const { data, id1, id2 } = edge;
    const inNode = nodes[id1]?.node.data;
    const outNode = nodes[id2]?.node.data;
    const {
      relationship,
      connectionType,
      topNodeRelationshipType,
      bottomNodeRelationshipType,
    } = data;

    return {
      data: { ...data, connectionType, relationship },
      topNode: {
        ...outNode,
        nodeRelationshipType: topNodeRelationshipType,
      },
      bottomNode: {
        ...inNode,
        nodeRelationshipType: bottomNodeRelationshipType,
      },
    };
  }
  return undefined;
});

export const selectIsLoading = createSelector(
  [graph],
  (state) => state.isLoading,
);
