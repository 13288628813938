import React from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedLinkId } from 'store/graph/actions';
import { RELATIONSHIP_TYPE_CHARITABLE, RELATIONSHIP_TYPE_EDUCATION, RELATIONSHIP_TYPE_ENGAGEMENT, RELATIONSHIP_TYPE_FAMILY, RELATIONSHIP_TYPE_ORGANIZATIONAL, RELATIONSHIP_TYPE_PERSONAL } from 'store/graph/constants';
import Charitable from './charitable';
import Family from './family';
import Personal from './personal';
import RelationshipHeader from './relationship-header';
import styles from './styles/relationshipCard.module.css';
import Organizational from './organizational';
import Education from './education';
import { CharitableIcon, EducationIcon, EngagementIcon, FamilyIcon, PersonalIcon, ProfessionalIcon } from 'common/icon/icon';
import Engagement from './engagement';



const Relationship = ({ selectedEdge }) => {
    const dispatch = useDispatch();
    const { data, topNode, bottomNode } = selectedEdge;
    const goBack = () => {
        dispatch(setSelectedLinkId(undefined));
    }

    const { connectionType, topNodeRelationshipType, bottomNodeRelationshipType } = data;
    let icon = <></>;
    let nodes = <></>;
    if (connectionType === RELATIONSHIP_TYPE_FAMILY) {
        icon = <FamilyIcon className={styles.family_icon} />;
        nodes = <Family topNode={topNode} bottomNode={bottomNode} data={data} topRelation={topNodeRelationshipType} bottomRelation={bottomNodeRelationshipType} />;
    } else if (connectionType === RELATIONSHIP_TYPE_PERSONAL) {
        icon = <PersonalIcon className={styles.professional_icon} />;
        nodes = <Personal topNode={topNode} bottomNode={bottomNode} data={data} topRelation={topNodeRelationshipType} bottomRelation={bottomNodeRelationshipType} />;
    } else if (connectionType === RELATIONSHIP_TYPE_CHARITABLE) {
        icon = <CharitableIcon className={styles.charitable_icon} />;
        nodes = <Charitable topNode={topNode} bottomNode={bottomNode} data={data} topRelation={topNodeRelationshipType} bottomRelation={bottomNodeRelationshipType} />;
    }
    else if (connectionType === RELATIONSHIP_TYPE_ORGANIZATIONAL) {
        icon = <ProfessionalIcon className={styles.organization_icon} />;
        nodes = <Organizational topNode={topNode} bottomNode={bottomNode} data={data} topRelation={topNodeRelationshipType} bottomRelation={bottomNodeRelationshipType} />;
    }
    else if (connectionType === RELATIONSHIP_TYPE_EDUCATION) {
        icon = <EducationIcon className={styles.education_icon} />;
        nodes = <Education topNode={topNode} bottomNode={bottomNode} topRelation={topNodeRelationshipType} bottomRelation={bottomNodeRelationshipType} />;
    }
    else if (connectionType === RELATIONSHIP_TYPE_ENGAGEMENT) {
        icon = <EngagementIcon />;
        nodes = <Engagement topNode={topNode} bottomNode={bottomNode} topRelation={topNodeRelationshipType} bottomRelation={bottomNodeRelationshipType} />;
    }

    return (
        <div className={styles.card}>
            <RelationshipHeader goBack={goBack} icon={icon} properties={data} />
            <div className={styles.content}>
                {nodes}
            </div>
        </div>
    );
}

export default Relationship;