import { createSelector } from "reselect";

const login = state => state.login;

export const selectIsLoading = createSelector([login], (state) => {
    return state.isLoading;
});

export const selectError = createSelector([login], (state) => {
    return state.error;
});
