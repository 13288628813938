import React, {useEffect, useState} from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { removeConstituencyFilter, setConstituencyFilter } from 'store/filters/actions';
import { selectConstituencyFilter } from 'store/filters/selectors';
import DropDown from '../../drop-down';
import styles from './constitencyType.module.css';

const ConstituencyTypeFilter = () => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const constits = useSelector(selectConstituencyFilter);
    const [search, setSearch] = useState("");
    const [showAdd, setShowAdd] = useState(false);
    const handleToggle = () => {
      setOpen(!open);        
    };
  
    const addValue = () => {
      dispatch(setConstituencyFilter(search.toLowerCase()));
      setSearch('');
    };
  
    const onChange = (e) => {
      if (e && e.preventDefault) e.preventDefault();
      const value = e.target.value;
      setSearch(value);
    };

    const handleClear = (region) => {
      dispatch(removeConstituencyFilter(region));
    };

    useEffect(() => {
      if(search.length > 0 && !showAdd) {
        setShowAdd(true);
      } else if(search.length < 1 && showAdd) {
        setShowAdd(false);
      }
    }, [search, setShowAdd, showAdd]);
  
    const constituencies = Object.keys(constits);
    return (
      <div className={!open ? 'filter_by_wrapper' : 'filter_by_open_wrapper'}>
        <DropDown filterText="Constituency" onClick={handleToggle} open={open} />
        {open && 
          <div className={styles.open_region}>
            <div className={styles.input_region}>
              <div className={styles.input_wrapper}>
                <input type='text' className={styles.input} onChange={onChange} value={search} placeholder='Enter Constituency' />
                {showAdd && <button className={styles.add} onClick={addValue}>Add</button>}
              </div>
            </div>
          </div>
        }
        {constituencies.length > 0 && <div className={styles.regions_wrapper}>{constituencies.map((x) => (
        <div className={styles.regions_item} key={x}>
          {x}
          <AiOutlineClose onClick={() => handleClear(x)}/>
        </div>))}
      </div>}
      </div>
    );
  }
  
  export default ConstituencyTypeFilter;