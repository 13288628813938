import { fork, all } from 'redux-saga/effects';
import coreSaga from './core/saga';
import graphSaga from './graph/saga';
import loginSaga from './login/saga';

export default function* rootSaga() {
    yield all([
        fork(graphSaga),
        fork(loginSaga),
        fork(coreSaga),
    ])
}