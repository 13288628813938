import React from "react";
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";
import styles from './input.module.css';

export const Input = ({ value = '', label }) => {
    let node = <></>;
    if(value) {
        node = <ReactTooltip id={`${label}-${value}`} place="top" effect="solid">{value}</ReactTooltip>;
    }
    return (
        <div className={styles.wrapper}>
            <label className={styles.input_label}>{label}</label>
            <input data-tip data-for={`${label}-${value}`}  className={styles.input} value={value} readOnly />
            {node} 
        </div>
    );
}

Input.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
}

export default Input;