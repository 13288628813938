import { SET_ERROR, SET_IS_LOADING } from './constants';

export const initialState = {
  error: '',
  isLoading: false,
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADING: {
      return { ...state, isLoading: action.loading };
    }
    case SET_ERROR: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default loginReducer;
