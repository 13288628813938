import React from 'react';
import { nodeType } from 'utils/constants';
import Donor from './cards/donor';
import EmployerCard from './cards/employer';
import FundRelationshipCard from './cards/fund';
import Education from './education';

const Charitable = ({topNode, bottomNode, topRelation, bottomRelation}) => {
    if(!bottomRelation)  bottomRelation = topRelation;
    let top =  <Donor properties={topNode} headerText={topRelation} />;
    if(topNode.nodeType === nodeType.Person) {
        top = <Donor properties={topNode} headerText={topRelation}/>;
    } 
    if(topNode.nodeType === nodeType.Organization) {
        top = <EmployerCard properties={topNode} headerText={topRelation}/>;
    } 
    if(topNode.nodeType === nodeType.Fund) {
        top = <FundRelationshipCard properties={topNode} headerText={topRelation}/>;
    } 
    if(topNode.nodeType === nodeType.Education) {
        top = <Education properties={topNode} headerText={topRelation}/>;
    }

    let bottom =  <FundRelationshipCard properties={bottomNode} headerText={bottomRelation} />;
    return (
        <>
            {top}
            {bottom}
        </>
    );
}

export default Charitable;
