export const edgeColors = {
  family: "#0960E4",
  personal: "#00A19B",
  professional: "#97B912",
  charitable: "#DEAF00",
  education: "#FF6624",
  engagement: "#D84884",
  personalRbga: "rgba(0, 161, 155, 0.25)",
  professionalRbga: "rgba(151, 185, 18, 0.25)",
  charitableRbga: "rgba(222, 175, 0, 0.25)",
  educationRbga: "rgba(255, 102, 36, 0.25)",
  engagementRbga: "rgba(216, 72, 132, 0.25)",
  undefined: "#000",
};

export const nodeColors = {
  person: "#A7DBEC",
  organization: "#D0DF98",
  fund: "#F0DB92",
  education: "#FEBBA0",
  engagement: "#EBADC7",
  contextNode: "#ff0000",
};

export const baseNodeColor = "#ceced3";
export const baseEdgeColor = "#ceced3";
export const nodeTextColor = "#303030";

export const nodeType = {
  Person: "Person",
  Organization: "Organization",
  Fund: "Fund",
  Education: "Education",
  Engagement: "Engagement",
};

export const totalConnectionsFilterMax = 1000;

export const SizingTypes = {
  1: "Most Connected",
  2: "Largest Donor",
  3: "Highest Capacity",
}