import React from 'react';
import { useDispatch } from 'react-redux';
import { setProspectStatus } from 'store/filters/actions';
import styles from './prospect.module.css';

const Item = ({value, prospectKey, isSelected}) => {
  const dispatch = useDispatch();
  const toggle = () => {
    dispatch(setProspectStatus(prospectKey, !isSelected));
  }
  return (
    <div className={!isSelected ? styles.item_wrapper : styles.item_wrapper_selected} onClick={toggle}>
      <p className={styles.item}>{value}</p>
    </div>
  )
}

export default Item;