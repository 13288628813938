import { useDispatch, useSelector } from 'react-redux';
import {
  hideFeedbackModal,
  sendFeedback,
  showFeedbackModal,
} from 'store/core/actions';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { FaCheckCircle } from 'react-icons/fa';
import styles from './feedback_modal.module.css';
import { useEffect, useState } from 'react';
import {
  selectFeedbackError,
  selectShowFeedbackModal,
} from 'store/core/selectors';

export const FeedbackIcon = () => {
  const dispatch = useDispatch();
  const openFeedbackModal = () => {
    dispatch(showFeedbackModal());
  };
  return (
    <div className={styles.feedback_button} onClick={openFeedbackModal}>
      <BsQuestionCircleFill />
    </div>
  );
};

export const FeedbackModel = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState('');
  const [titleError, setTitleError] = useState('');
  const [detailsError, setDetailsError] = useState('');
  const feedbackError = useSelector(selectFeedbackError);
  const showSuccessMessage = useSelector(selectShowFeedbackModal);
  const close = () => {
    dispatch(hideFeedbackModal());
  };

  const submit = () => {
    setIsLoading(true);
    if (!title) {
      setTitleError('Please enter a title!');
      setIsLoading(false);
      return;
    } else {
      setTitleError('');
    }

    if (!details) {
      setDetailsError(
        'Please enter any details around the issues or feedback!',
      );
      setIsLoading(false);
      return;
    } else {
      setDetailsError('');
    }

    var _navigator = {};
    for (var i in window.navigator) _navigator[i] = navigator[i];
    const systemInfo = JSON.stringify(_navigator);
    const request = {
      title,
      details,
      systemInfo,
    };
    dispatch(sendFeedback(request));
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleDetailsChange = (e) => {
    setDetails(e.target.value);
  };

  useEffect(() => {
    if (feedbackError) {
      setIsLoading(false);
    }
    if (showSuccessMessage === 'success') {
      setTimeout(close, 1200);
    }
  }, [close, feedbackError, showSuccessMessage]);

  let content = (
    <div className={styles.card}>
      <h1 className={styles.header}>Feedback</h1>
      <div className={styles.subheader}>
        Please enter a title and any details/issues. Thank you for your
        feedback!
      </div>
      <label className={styles.label}>Title*</label>
      <input
        className={styles.input}
        onChange={handleTitleChange}
        placeholder="Enter title. Ex: Issue in application"
      />
      {titleError && <div className={styles.error}>{titleError}</div>}
      <label className={styles.label}>Details*</label>
      <textarea
        className={styles.textarea}
        onChange={handleDetailsChange}
        placeholder="Enter feedback or describe & if possible, provide reproduction steps for any issues"
      />
      {detailsError && <div className={styles.error}>{detailsError}</div>}
      <div className={styles.button_wrapper}>
        <div className={styles.base_button} onClick={close}>
          Cancel
        </div>
        <div className={styles.submit_button} onClick={submit}>
          {!isLoading && (
            <span style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
              Submit
            </span>
          )}
          {isLoading && (
            <div
              className="lds-ring"
              style={{ visibility: isLoading ? 'visible' : 'hidden' }}
            >
              <div></div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.error}>{feedbackError}</div>
    </div>
  );

  if (showSuccessMessage === 'success') {
    content = (
      <div className={styles.card_success}>
        <div className={styles.success_content}>
          <FaCheckCircle className={styles.success_icon} />
          <div>We have recieved you're feedback!</div>
        </div>
      </div>
    );
  }

  return <div className={styles.root}>{content}</div>;
};
