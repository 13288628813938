import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from "containers/login";
import Home from 'containers/home';
import ProtectedRoute from "common/protected-route";
import styles from './app.module.css';


function App() {
  return (
    <div className={styles.app}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/" element={
              <ProtectedRoute path="/">
                <Home />
              </ProtectedRoute>
          }></Route>
        </Routes>
      </Router>
    </div>
  );
}


export default App;
