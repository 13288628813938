import React from 'react';
import Person from './cards/person';
import EngagementCard from './cards/engagement';

const Engagement = ({topNode, bottomNode, topRelation, bottomRelation }) => {
    if(!bottomRelation)  bottomRelation = topRelation;
    return (
        <>
            <EngagementCard properties={topNode} headerText={topRelation} />
            <Person properties={bottomNode} headerText={bottomRelation} />
        </>
    );
}

export default Engagement;
