import React from 'react';
import Person from './cards/person';

const Family = ({topNode, bottomNode, topRelation, bottomRelation }) => {
    if(!bottomRelation)  bottomRelation = topRelation;
    return (
        <>
            <Person properties={topNode} headerText={topRelation} />
            <Person properties={bottomNode} headerText={bottomRelation} />
        </>
    );
}

export default Family;
