import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'regraph';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedLinkId, setSelectedVertex } from 'store/graph/actions';
import PersonCard from 'common/node-cards/person-card';
import OrganizationCard from 'common/node-cards/organization';
import FundCard from 'common/node-cards/fund';
import { AiOutlineExport } from 'react-icons/ai';
import EducationCard from 'common/node-cards/education';
import {
  selectContextIds,
  selectNodes,
  selectSelectedEdgeId,
} from 'store/graph/selectors';
import { nodeType } from 'utils/constants';
import { distances, shortestPaths } from 'regraph/analysis';
import {
  GetNodeHoverStyles,
  GetNodeSelectionStyles,
  isLink,
  IsObjectEmpty,
} from 'utils/graph-helper';
import {
  selectShortestPathNodeId,
  selectShowVisibleConnections,
} from 'store/filters/selectors';
import { setShortestPathNodeId } from 'store/filters/actions';
import EngagementCard from 'common/node-cards/engagement';
import styles from './graph.module.css';

const chartOptions = {
  hoverDelay: 0,
  selection: { color: 'rgba(0,0,0,0)', labelColor: '#2e3842' },
  dragPan: false,
  fit: 'all',
  iconFontFamily: 'Font Awesome 5 Free',
  imageAlignment: { 'fa-user': { size: 0.9, dy: -3 } },
  minZoom: -1,
  overview: false,
  navigation: false,
};

// items is an object, not array
export const ReGraphChart = ({ items }) => {
  const dispatch = useDispatch();
  const length = Object.keys(items).length;
  const edgeId = useSelector(selectSelectedEdgeId);
  const contextids = useSelector(selectContextIds);
  const showVisible = useSelector(selectShowVisibleConnections);
  const shortestPathNodeId = useSelector(selectShortestPathNodeId);
  const nodesDict = useSelector(selectNodes);
  const [visibleConnections, setVisibleConnections] = useState(null);
  const [paths, setPathsConnections] = useState(null);
  const [state, setState] = useState({
    positions: {},
    selection: {},
    zoom: 1,
    offset: { x: 0, y: 0 },
    annotationPosition: null,
    isDragging: false,
  });
  const chartRef = useRef(null);

  const downloadImage = () => {
    chartRef.current.export({ type: 'png' }).then((exportedImage) => {
      exportedImage.download('chart');
    });
  };

  useEffect(() => {
    if (!edgeId) {
      setState((current) => {
        return {
          ...current,
          annotationPosition: null,
          selection: {},
        };
      });
    }
  }, [edgeId]);

  // remove the annotation if the window changes size
  useEffect(() => {
    const handleResize = () => {
      setState((current) => {
        if (firstKey(current.selection) == null) {
          return current;
        }

        return {
          ...current,
          selection: {},
        };
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function firstKey(record) {
    if (record == null) {
      return null;
    }
    const keys = Object.keys(record);
    return keys[0];
  }

  function isNode(item) {
    return item && item.id1 == null && item.id2 == null;
  }

  const onChangeHandler = ({
    positions: newPositions,
    selection: newSelection,
  }) => {
    if (newPositions == null && newSelection == null) {
      return;
    }

    const chart = chartRef.current;
    if (chart == null) {
      return;
    }

    const selectedItemId = firstKey(newSelection);
    if (selectedItemId != null) {
      const selectedItem = items[selectedItemId];
      if (selectedItem.id1) {
        dispatch(setSelectedLinkId(selectedItemId));
      }
    } else {
      dispatch(setSelectedLinkId(undefined));
    }

    setState((current) => {
      const nextPositions = newPositions || current.positions;
      let nextSelection = current.selection;
      let nextAnnotationPosition = current.annotationPosition;

      if (newSelection) {
        const selectedItemId = firstKey(newSelection);
        if (selectedItemId != null) {
          const selectedItem = items[selectedItemId];
          if (isNode(selectedItem)) {
            nextSelection = { [selectedItemId]: true };
            nextAnnotationPosition = chart.viewCoordinates(
              nextPositions[selectedItemId].x,
              nextPositions[selectedItemId].y,
            );
          } else if (selectedItem.id1 !== null) {
            nextSelection = { [selectedItemId]: true };
            nextAnnotationPosition = null;
          }
        } else {
          nextSelection = {};
        }
      }

      return {
        ...current,
        annotationPosition: nextAnnotationPosition,
        selection: nextSelection,
        positions: nextPositions,
      };
    });
  };

  const onItemInteractionHandler = ({
    id,
    item,
    hovered,
    selected,
    setStyle,
  }) => {
    if (item && item.id1 && item.id2) {
      setStyle({
        [id]: {
          color: item.activeColor || '#f58c84',
        },
      });
    } else if (isNode(item)) {
      let newStyle = {};
      if (hovered) {
        // change
        const fullNode = nodesDict[item.id]?.node;
        const hover = GetNodeHoverStyles(fullNode);
        newStyle = hover;
      } else if (selected) {
        // change
        const fullNode = nodesDict[item.id]?.node;
        const selection = GetNodeSelectionStyles(fullNode);
        newStyle = { ...newStyle, ...selection };
      }
      setStyle({
        [id]: newStyle,
      });
    }
  };

  const onDragHandler = ({ type, x, y, draggedItems }) => {
    if (
      type !== 'node' ||
      firstKey(draggedItems) !== firstKey(state.selection)
    ) {
      return;
    }
    // if dragging the selected node the position isn't updated until the
    // onChange event, so calculate it from the cursor
    setState((current) => {
      return {
        ...current,
        isDragging: true,
        annotationPosition: {
          x: x - current.offset.x,
          y: y - current.offset.y,
        },
      };
    });
  };

  const onDragEndHandler = ({ defaultPrevented }) => {
    setState((current) => {
      const chart = chartRef.current;
      const selectedItemId = firstKey(current.selection);
      if (
        !defaultPrevented ||
        chart == null ||
        selectedItemId == null ||
        current.positions[selectedItemId] == null
      ) {
        return { ...current, isDragging: false };
      }

      const annotationPosition = chart.viewCoordinates(
        current.positions[selectedItemId].x,
        current.positions[selectedItemId].y,
      );

      return {
        ...current,
        isDragging: false,
        annotationPosition,
      };
    });
  };

  // store the offset to the cursor position to correct during a drag
  const onPointerDownHandler = ({ id, x, y }) => {
    const item = items[id];
    const chart = chartRef.current;
    if (id == null || !isNode(item) || chart == null) {
      return;
    }
    setState((current) => {
      const position = chart.viewCoordinates(
        current.positions[id].x,
        current.positions[id].y,
      );
      const offset = {
        x: x - position.x,
        y: y - position.y,
      };

      return {
        ...current,
        offset,
      };
    });
  };

  const onClick = ({ subItem }) => {
    if (subItem && subItem.type === 'glyph') {
      removeShortestPathId();
    }
  };

  const removeShortestPathId = () => {
    dispatch(setShortestPathNodeId(''));
  };

  const onViewChangeHandler = ({ zoom }) => {
    const chart = chartRef.current;
    if (chart == null) {
      return;
    }

    const selectedItemId = firstKey(state.selection);
    if (
      state.zoom === zoom &&
      (selectedItemId == null || state.positions[selectedItemId] == null)
    ) {
      return;
    }

    setState((current) => {
      let { annotationPosition } = current;
      if (selectedItemId != null && current.positions[selectedItemId] != null) {
        annotationPosition = chart.viewCoordinates(
          current.positions[selectedItemId].x,
          current.positions[selectedItemId].y,
        );
      }

      return {
        ...current,
        zoom,
        annotationPosition,
      };
    });
  };

  const onWheelHandler = ({ preventDefault }) => {
    if (state.isDragging) {
      preventDefault();
    }
  };

  const selectedItemId = firstKey(state.selection);
  const item = items[selectedItemId];

  useEffect(() => {
    const visible = async (paths) => {
      let connectedNodes = {};
      const itemsToCheck = shortestPathNodeId ? paths : items;
      for (var i in contextids) {
        const nodes = await distances(itemsToCheck, i, { direction: 'any' });
        connectedNodes = { ...connectedNodes, ...nodes };
      }
      let newItems = {};
      for (const key in items) {
        if (isLink(items[key])) {
          newItems[key] = items[key];
        } else if (connectedNodes[key] !== undefined) {
          newItems[key] = items[key];
        }
      }
      setVisibleConnections(newItems);
    };

    if (showVisible) {
      visible(paths);
    }
  }, [contextids, items, showVisible]);

  useEffect(() => {
    const visible = async (nodeId) => {
      let connectedNodes = {};
      for (var i in contextids) {
        const nodes = await shortestPaths(items, nodeId, i, {
          direction: 'any',
        });
        connectedNodes = { ...connectedNodes, ...nodes.items };
      }
      if (IsObjectEmpty(connectedNodes)) {
        removeShortestPathId();
      }
      setPathsConnections(connectedNodes);
    };

    if (
      shortestPathNodeId &&
      shortestPathNodeId !== null &&
      shortestPathNodeId !== undefined
    ) {
      visible(shortestPathNodeId);
    } else {
    }
  }, [contextids, items, shortestPathNodeId]);

  const itemsToShow = shortestPathNodeId ? paths : items;
  const chartItems = showVisible ? visibleConnections : itemsToShow;
  return (
    <div className={styles.chart_wrapper}>
      {length === 0 && !IsObjectEmpty(contextids) && (
        <div className={styles.no_error}>No Results</div>
      )}
      <Chart
        ref={chartRef}
        items={chartItems}
        selection={state.selection}
        animation={{ animate: true, time: 500 }}
        options={chartOptions}
        onChange={onChangeHandler}
        onDrag={onDragHandler}
        onDragEnd={onDragEndHandler}
        onPointerDown={onPointerDownHandler}
        onViewChange={onViewChangeHandler}
        onClick={onClick}
        onWheel={onWheelHandler}
        onItemInteraction={onItemInteractionHandler}
      />
      {selectedItemId != null &&
        item !== undefined &&
        isNode(item) &&
        state.annotationPosition != null && (
          <Annotation
            position={state.annotationPosition}
            properties={item.data}
            size={item.size}
            zoom={state.zoom}
            itemId={selectedItemId}
            nodesDict={nodesDict}
          />
        )}
      {shortestPathNodeId && IsObjectEmpty(chartItems) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, 0)',
            background: '#fff',
            border: '1px solid black',
            padding: '20px 10px',
            borderRadius: '4px',
          }}
        >
          <p style={{ margin: '0', paddingBottom: '8px' }}>
            There is no path to a context node
          </p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '6px 10px',
              background: 'var(--skyBlueLight)',
              border: ' 1px solid var(--skyBlue)',
              borderRadius: '4px',
              outline: 'none',
              cursor: 'pointer',
              fontWeight: 700,
              color: '#fff',
              textTransform: 'uppercase',
              fontSize: '12px',
              marginTop: '8px',
            }}
            onClick={removeShortestPathId}
          >
            Go Back
          </div>
        </div>
      )}
      <div className={styles.print_button} onClick={downloadImage}>
        <AiOutlineExport />
      </div>
    </div>
  );
};

ReGraphChart.propTypes = {
  items: PropTypes.object,
};

function Annotation({ position, itemId, nodesDict }) {
  const dispatch = useDispatch();
  const fullNode = nodesDict[itemId]?.node;
  const properties = fullNode.data;
  const isPerson = properties.nodeType === nodeType.Person;
  const { top, left } = getPosition(position, isPerson);
  const style = {
    top: `${top}px`,
    left: `${left}px`,
    position: 'absolute',
  };

  const moreInformation = () => {
    dispatch(setSelectedVertex(itemId));
  };

  return (
    <div className="annotation" style={style}>
      <div className="annotation-arrow" />
      {isPerson && (
        <PersonCard properties={properties} moreInformation={moreInformation} />
      )}
      {properties.nodeType === nodeType.Organization && (
        <OrganizationCard
          properties={properties}
          moreInformation={moreInformation}
        />
      )}
      {properties.nodeType === nodeType.Fund && (
        <FundCard properties={properties} moreInformation={moreInformation} />
      )}
      {properties.nodeType === nodeType.Education && (
        <EducationCard
          education={properties}
          moreInformation={moreInformation}
        />
      )}
      {properties.nodeType === nodeType.Engagement && (
        <EngagementCard
          properties={properties}
          moreInformation={moreInformation}
        />
      )}
    </div>
  );
}

const getPosition = (position, isPerson) => {
  const { x, y } = position;
  let left = x;
  let top = y;

  var remainingWidth = window.innerWidth - x;
  var remainingHeight = window.innerHeight - y;
  if (remainingWidth <= 400) {
    left = x - 400;
  } else {
    left = x + 50;
  }
  if (remainingHeight <= 300) {
    if (isPerson) {
      top = y - 450;
    } else {
      top = y - 400;
    }
  } else if (remainingHeight <= 400) {
    top = y - 300;
  } else if (remainingHeight > 500 && y <= 200) {
    top = y;
  } else {
    top = y - 110;
  }
  return { top, left };
};

export default ReGraphChart;
