import styles from './lastUpdated.module.css';

const LastUpdated = ({ ingestionDateUtc }) => {
  const date = new Date(ingestionDateUtc);
  return (
    <div className={styles.updated_date}>
      <strong>Last Updated:</strong> {date.toLocaleString()}
    </div>
  );
};

export default LastUpdated;
