import React from "react";
import PropTypes from 'prop-types';
import { BsChevronRight } from 'react-icons/bs';
import CardHeader from "../../card-header";
import styles from '../styles/employee.module.css';
import Img from "common/img-container";
import TypesBar from "common/types-bar";

const Employee = ({ properties, headerText }) => {
    const { MetroRegion, name, age, maidenname = '', img_src = '', alum, activelymanaged, donor} = properties;
    return (
        <div className={styles.card}>
            <CardHeader value={headerText} />
            <div className={styles.content}>
                <div className={styles.content_header}>
                    <div className={styles.left_section}>
                        <div className={styles.name}>
                            <h2 className={styles.name_desc}>{name}</h2>
                            <BsChevronRight className={styles.name_icon} />
                        </div>
                        <div className={styles.person_details_wrapper}>
                            <div className={styles.person_details_title}>Age:</div>
                            <div className={styles.person_details_value}>{age}</div>
                        </div>
                        <div className={styles.person_details_wrapper}>
                            <div className={styles.person_details_title}>Maiden:</div>
                            <div className={styles.person_details_value}>{maidenname}</div>
                        </div>
                        <div className={styles.person_details_wrapper}>
                            <div className={styles.person_details_title}>Region:</div>
                            <div className={styles.person_details_value}>{MetroRegion}</div>
                        </div>
                    </div>
                    <div className={styles.right_section}>
                        <Img src={img_src} size="64px" />
                    </div>
                </div>
                <div className={styles.types}>
                    <TypesBar showAlumni={alum === 1} showManaged={activelymanaged} showDonor={donor === 1} iconColor='#38B2D9' />
                </div>
            </div>
        </div>
    );
}

Employee.propTypes = {
    total_giving: PropTypes.string,
    affiliation_unit: PropTypes.string,
    prospect_status: PropTypes.object,
}

export default Employee;