import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectItems } from 'store/graph/selectors';
import ReGraphChart from './chart';

// items is an object, not array
export const ReGraphChartWrapper = () => {
  const items = useSelector(selectItems);
  return <ReGraphChart items={items} />;
};

ReGraphChartWrapper.propTypes = {
  items: PropTypes.object,
};

export default ReGraphChartWrapper;
