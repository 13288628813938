import React from "react";
import PropTypes from 'prop-types';
import styles from './searchInput.module.css';
import { CharitableIcon, EducationIcon, EngagementIcon, PersonIcon, ProfessionalIcon } from "common/icon/icon";

const SearchItem = ({ item, onSelect }) => {
    let label = 'person';
    const onClick = (e) => {
        if (e && e.preventDefault) e.preventDefault();
        onSelect({
            ...item,
            type: 'vertex',
            label: label,
            value: item.displayValue,
        });
    }

    let icon = <div className={styles.search_item_icon}></div>;
    if (item.id.includes('pers')) {
        icon = <PersonIcon className={styles.search_icon} />;
        label = 'person';
    } else if (item.id.includes('educat')) {
        icon = <EducationIcon className={styles.education_icon} />;
        label = 'education';
    } else if (item.id.includes('fund')) {
        icon = <CharitableIcon className={styles.charitable_icon} />;
        label = 'fund';
    } else if (item.id.includes('org')) {
        icon = <ProfessionalIcon className={styles.organization_icon} />;
        label = 'org';
    } else if (item.id.includes('eng')) {
        icon = <EngagementIcon />;
        label = 'eng';
    }

    return (
        <div className={styles.search_item} onClick={onClick}>
            {icon}
            <div className={styles.search_item_value}>{item.displayValue}</div>
        </div>
    );
}

SearchItem.propTypes = {
    // id, metroRegion, displayValue
    item: PropTypes.object,
    onSelect: PropTypes.func
};

export default SearchItem;