import React from "react";
import PropTypes from 'prop-types';
import { BsChevronRight } from 'react-icons/bs';
import CardHeader from "../../card-header";
import Input from '../../input';
import styles from '../styles/person-relationship-card.module.css';
import { CharitableIcon } from "common/icon/icon";

const FundRelationshipCard = ({ properties, headerText }) => {
    const { institution, college, department,  fundName, purpose, unknown } = properties;

    return (
        <div className={styles.card}>
            <CardHeader value={headerText} icon={<CharitableIcon className={styles.charitable_icon} />} />
            <div className={styles.content}>
                <div className={styles.content_header}>
                    <div className={styles.left_section}>
                        <div className={styles.name}>
                            <h2 className={styles.name_desc}>{fundName}</h2>
                            <BsChevronRight className={styles.name_icon} />
                        </div>
                        <div className={styles.person_details_wrapper}>
                            <div className={styles.person_details_title}>Institution:</div>
                            <div className={styles.person_details_value}>{institution}</div>
                        </div>
                        <div className={styles.person_details_wrapper}>
                            <div className={styles.person_details_title}>College:</div>
                            <div className={styles.person_details_value}>{college}</div>
                        </div>
                        <div className={styles.person_details_wrapper}>
                            <div className={styles.person_details_title}>Department:</div>
                            <div className={styles.person_details_value}>{department}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.affiliation_unit}>
                    <Input value={purpose} label="Purpose" />
                </div>
                <div className={styles.affiliation_unit}>
                    <Input value={unknown} label="" />
                </div>
            </div>
        </div>
    );
}

FundRelationshipCard.propTypes = {
    properties: PropTypes.object,
}

export default FundRelationshipCard;