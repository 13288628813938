// node filters
export const SET_PROSPECT_STATUS_FILTER =
  'APP/FILTERS/SET_PROSPECT_STATUS_FILTER';
export const SET_DEGREES_OF_SEPARATION =
  'APP/FILTERS/SET_DEGREES_OF_SEPARATION';
export const SET_NODE_SIZING_TYPE = 'APP/FILTERS/SET_NODE_SIZING_TYPE';
export const SET_TOTAL_CONNECTIONS = 'APP/FILTERS/SET_TOTAL_CONNECTIONS';
export const SET_MIN_LIFETIME_GIVING = 'APP/FILTERS/SET_MIN_LIFETIME_GIVING';
export const SET_MAX_LIFETIME_GIVING = 'APP/FILTERS/SET_MAX_LIFETIME_GIVING';
export const SET_REGION_FILTER = 'APP/FILTERS/SET_REGION_FILTER';
export const REMOVE_REGION_FILTER = 'APP/FILTERS/REMOVE_REGION_FILTER';
export const TOGGLE_SHOW_DECEASED_CONNECTIONS =
  'APP/FILTERS/TOGGLE_SHOW_DECEASED_CONNECTIONS';
export const SET_NODE_OPTIONS_FILTER = 'APP/FILTERS/SET_NODE_OPTIONS_FILTER';
export const SET_NODE_OPTIONS_FILTER_ENABLED =
  'APP/FILTERS/SET_NODE_OPTIONS_FILTER_ENABLED';
export const SET_NODE_TYPE_FILTER = 'APP/FILTERS/SET_NODE_TYPE_FILTER';
export const SET_AFFILIATION_FILTER = 'APP/FILTERS/SET_AFFILIATION_FILTER';
export const SET_CONSTITUENCY_FILTER = 'APP/FILTERS/SET_CONSTITUENCY_FILTER';
export const REMOVE_CONSTITUENCY_FILTER =
  'APP/FILTERS/REMOVE_CONSTITUENCY_FILTER';
export const CLEAR_NODE_FILTERS = 'APP/FILTERS/CLEAR_NODE_FILTERS';
export const SET_VISIBLE_CONNECTIONS = 'APP/FILTERS/SET_VISIBLE_CONNECTIONS';
export const SET_SHORTEST_PATH_NODE_ID =
  'APP/FILTERS/SET_SHORTEST_PATH_NODE_ID';

export const SET_VIEW_LOADING = 'APP/FILTERS/SET_VIEW_LOADING';

// edge filters
export const SET_FAMILY_OPTIONS_FILTER =
  'APP/FILTERS/SET_FAMILY_OPTIONS_FILTER';
export const SET_PROFESSIONAL_OPTIONS_FILTER =
  'APP/FILTERS/SET_PROFESSIONAL_OPTIONS_FILTER';
export const SET_ORGANIZATIONAL_OPTIONS_FILTER =
  'APP/FILTERS/SET_ORGANIZATIONAL_OPTIONS_FILTER';
export const SET_CHARITABLE_OPTIONS_FILTER =
  'APP/FILTERS/SET_CHARITABLE_OPTIONS_FILTER';
export const SET_EDUCATION_OPTIONS_FILTER =
  'APP/FILTERS/SET_EDUCATION_OPTIONS_FILTER';
export const SET_ENGAGEMENT_OPTIONS_FILTER =
  'APP/FILTERS/SET_ENGAGEMENT_OPTIONS_FILTER';
export const SET_FAMILY_OPTIONS_FILTER_ENABLED =
  'APP/FILTERS/SET_FAMILY_OPTIONS_FILTER_ENABLED';
export const SET_PROFESSIONAL_OPTIONS_FILTER_ENABLED =
  'APP/FILTERS/SET_PROFESSIONAL_OPTIONS_FILTER_ENABLED';
export const SET_ORGANIZATIONAL_OPTIONS_FILTER_ENABLED =
  'APP/FILTERS/SET_ORGANIZATIONAL_OPTIONS_FILTER_ENABLED';
export const SET_CHARITABLE_OPTIONS_FILTER_ENABLED =
  'APP/FILTERS/SET_CHARITABLE_OPTIONS_FILTER_ENABLED';
export const SET_EDUCATION_OPTIONS_FILTER_ENABLED =
  'APP/FILTERS/SET_EDUCATION_OPTIONS_FILTER_ENABLED';
export const SET_ENGAGEMENT_OPTIONS_FILTER_ENABLED =
  'APP/FILTERS/SET_ENGAGEMENT_OPTIONS_FILTER_ENABLED';
export const SET_EDGE_FILTER_TYPE = 'APP/FILTERS/SET_EDGE_FILTER_TYPE';
export const SET_VIEW_FILTERS = 'APP/FILTERS/SET_VIEW_FILTERS';
export const ADD_HIDDEN_NODE = 'APP/FILTERS/ADD_HIDDEN_NODE';
export const CLEAR_HIDDEN_NODE = 'APP/FILTERS/CLEAR_HIDDEN_NODE';
export const RESET_HIDDEN_NODES = 'APP/FILTERS/RESET_HIDDEN_NODES';

// constants
export const HIGHLIGHTED_STATE = 'HIGHLIGHTED_STATE';
export const SELECTED_STATE = 'SELECTED_STATE';
export const UNSELECTED_STATE = 'UNSELECTED_STATE';
export const FILTER_OUT_SELECTED_STATE = 'FILTER_OUT_SELECTED_STATE';

export const getInitialProspectState = () => ({
  0: {
    value: '< $2,500',
    text: '<$2,500',
    isSelected: false,
  },
  1: {
    value: '$2,500 - $4,999',
    text: '$2,500-$4,999',
    isSelected: false,
  },
  2: {
    value: '$5,000 - $9,999',
    text: '$5,000-$9,999',
    isSelected: false,
  },
  3: {
    value: '$10,000 - $24,999',
    text: '$10K-$24.9K',
    isSelected: false,
  },
  4: {
    value: '$25,000 - $49,999',
    text: '$25K-$49.9K',
    isSelected: false,
  },
  5: {
    value: '$50,000 - $99,999',
    text: '$50K-$99.9K',
    isSelected: false,
  },
  6: {
    value: '$100,000 - $249,999',
    text: '$100K-$249.9K',
    isSelected: false,
  },
  7: {
    value: '$250,000 - $499,999',
    text: '$250K-$499.9K',
    isSelected: false,
  },
  8: {
    value: '$500,000 - $999,999',
    text: '$500K-$999.9K',
    isSelected: false,
  },
  9: {
    value: '$1,000,000 - $4,999,999',
    text: '$1M-$4.9M',
    isSelected: false,
  },
  10: {
    value: '$5,000,000 -$9,999,999',
    text: '$5M-$9.9M',
    isSelected: false,
  },
  11: {
    value: '$10,000,000 - $24,999,999',
    text: '$10M-$24.9M',
    isSelected: false,
  },
  12: {
    value: '$25,000,000 - $49,999,999',
    text: '$25M-$49.9M',
    isSelected: false,
  },
  13: {
    value: '$50,000,000 - $99,999,999',
    text: '$50M-$99.9M',
    isSelected: false,
  },
  14: {
    value: '$100,000,000 - $249,999,999',
    text: '$100M-$249.9M',
    isSelected: false,
  },
  15: {
    value: '$250,000,000+',
    text: '$250M+',
    isSelected: false,
  },
});

export const initialNodeFilters = {
  regions: '',
  degreesOfSeparation: 1,
  nodeSizingType: 0,
  prospectStatus: getInitialProspectState(),
  totalLifetimeGivingMax: undefined,
  totalLifetimeGivingMin: undefined,
  totalConnections: 0,
  affiliation: '',
  showDeceasedConnections: true,
  constituency: {},
};

export const initialEdgeFilters = {
  viewFilterUpdates: undefined,
  familyOptions: [],
  professionalOptions: [],
  organizationalOptions: [],
  charitableOptions: [],
  educationOptions: [],
  engagementOptions: [],
  nodeFilterOptions: [],
  familyOptionsEnabled: false,
  professionalOptionsEnabled: false,
  organizationalOptionsEnabled: false,
  charitableOptionsEnabled: false,
  educationOptionsEnabled: false,
  engagementOptionsEnabled: false,
  nodeFilterOptionsEnabled: false,
  familyOptionsTimeFilter: { start: null, end: null },
  professionalOptionsTimeFilter: { start: null, end: null },
  organizationalOptionsTimeFilter: { start: null, end: null },
  charitableOptionsTimeFilter: { start: null, end: null },
  educationOptionsTimeFilter: { start: null, end: null },
  engagementOptionsTimeFilter: { start: null, end: null },
  nodeFilterOptionsTimeFilter: { start: null, end: null },
  nodeFilterType: UNSELECTED_STATE,
  familyFilterType: UNSELECTED_STATE,
  professionalFilterType: UNSELECTED_STATE,
  organizationalFilterType: UNSELECTED_STATE,
  charitableFilterType: UNSELECTED_STATE,
  educationFilterType: UNSELECTED_STATE,
  engagementFilterType: UNSELECTED_STATE,
  nodeFilterOut: [],
  edgesFilterOut: {
    familyOptions: [],
    professionalOptions: [],
    organizationalOptions: [],
    charitableOptions: [],
    educationOptions: [],
    engagementOptions: [],
    nodeFilterOptions: [],
  },
  showVisibleConnections: false,
};

export const familyInterestsFiltersView = {
  familyOptions: [
    'Parent-Child',
    'Grandparent-Grandchild',
    'Aunt/Uncle-Niece/Nephew',
    'Cousin',
    'In-law',
    'Relative',
    'Sibling',
    'Spouse/Partner',
    'Step Relative',
  ],
  organizationalOptions: ['Employer-Employee'],
  charitableOptions: ['Donor-Fund'],
  educationOptions: ['Student-UI Education', 'Student-External Education'],
  engagementOptions: ['Organization-Leader', 'Organization-Member'],
  professionalOptions: [],
  familyOptionsEnabled: true,
  organizationalOptionsEnabled: true,
  charitableOptionsEnabled: true,
  educationOptionsEnabled: true,
  engagementOptionsEnabled: true,
  familyFilterType: 'HIGHLIGHTED_STATE',
  organizationalFilterType: 'HIGHLIGHTED_STATE',
  charitableFilterType: 'HIGHLIGHTED_STATE',
  educationFilterType: 'HIGHLIGHTED_STATE',
  engagementFilterType: 'HIGHLIGHTED_STATE',
  nodeFilterOptionsEnabled: true,
  nodeFilterType: 'HIGHLIGHTED_STATE',
  nodeFilterOptions: [
    'Person',
    'Organization',
    'Education',
    'Fund',
    'Engagement',
  ],
};

export const colleaguesFiltersView = {
  familyOptions: [],
  professionalOptions: [],
  organizationalOptions: [
    'Employer-Employee',
    'Colleague',
    'Employer-Postdoctoral Researcher',
    'Service Provider-Client',
    'Collaborator',
    'Instructor/Advisor-Student',
    'Postdoctoral Supervisor-Postdoctoral Researcher',
    'Power of Attorney-Client',
  ],
  charitableOptions: [],
  educationOptions: [],
  engagementOptions: [],
  organizationalOptionsEnabled: true,
  organizationalFilterType: 'HIGHLIGHTED_STATE',
  nodeFilterOptionsEnabled: true,
  nodeFilterType: 'HIGHLIGHTED_STATE',
  nodeFilterOptions: [
    'Person',
    'Organization',
    'Education',
    'Fund',
    'Engagement',
  ],
};
export const classmatesFiltersView = {
  familyOptions: [],
  professionalOptions: [],
  organizationalOptions: [],
  charitableOptions: [],
  educationOptions: [
    'Student-UI Education',
    'Organization-UI Education',
    'Student-External Education',
    'Organization-External Education',
  ],
  engagementOptions: [],
  educationOptionsEnabled: true,
  educationFilterType: 'HIGHLIGHTED_STATE',
  nodeFilterOptionsEnabled: true,
  nodeFilterType: 'HIGHLIGHTED_STATE',
  nodeFilterOptions: [
    'Person',
    'Organization',
    'Education',
    'Fund',
    'Engagement',
  ],
};

export const advancedConnectionsView = {
  familyOptions: [],
  professionalOptions: [
    'Prospect-Prospect Manager',
    'Prospect-Primary Plan Manager',
    'Prospect-Secondary Solicitor',
  ],
  organizationalOptions: ['Prospect-Interaction Owner'],
  charitableOptions: [],
  educationOptions: [],
  engagementOptions: [],
  professionalOptionsEnabled: true,
  professionalFilterType: 'HIGHLIGHTED_STATE',
  organizationalOptionsEnabled: true,
  organizationalFilterType: 'HIGHLIGHTED_STATE',
  nodeFilterOptionsEnabled: true,
  nodeFilterType: 'HIGHLIGHTED_STATE',
  nodeFilterOptions: [
    'Person',
    'Organization',
    'Education',
    'Fund',
    'Engagement',
  ],
};

export const topPhilantrophicView = {
  familyOptions: [],
  professionalOptions: [],
  organizationalOptions: [],
  charitableOptions: [
    'Donor-Fund',
    'Donor-Matching Organization',
    'Donor-Credit Organization',
    'Donor-Organization',
  ],
  educationOptions: [],
  engagementOptions: [],
  charitableOptionsEnabled: true,
  charitableFilterType: 'HIGHLIGHTED_STATE',
  nodeFilterOptionsEnabled: true,
  nodeFilterType: 'HIGHLIGHTED_STATE',
  nodeFilterOptions: [
    'Person',
    'Organization',
    'Education',
    'Fund',
    'Engagement',
  ],
};

export const engagementView = {
  familyOptions: [
    'Parent-Child',
    'Grandparent-Grandchild',
    'Aunt/Uncle-Niece/Nephew',
    'Cousin',
    'In-law',
    'Relative',
    'Sibling',
    'Spouse/Partner',
    'Step Relative',
  ],
  familyOptionsEnabled: true,
  familyFilterType: 'HIGHLIGHTED_STATE',
  professionalOptions: [
    'Prospect-Prospect Manager',
    'Prospect-Primary Plan Manager',
    'Prospect-Secondary Solicitor',
  ],
  organizationalOptions: ['Prospect-Interaction Owner'],
  charitableOptions: [],
  educationOptions: [],
  engagementOptions: [
    'Organization-Leader',
    'Organization-Member',
    'Organization-Staff',
  ],
  organizationalOptionsEnabled: true,
  organizationalFilterType: 'HIGHLIGHTED_STATE',
  professionalOptionsEnabled: true,
  professionalFilterType: 'HIGHLIGHTED_STATE',
  engagementOptionsEnabled: true,
  engagementFilterType: 'HIGHLIGHTED_STATE',
  nodeFilterOptionsEnabled: true,
  nodeFilterType: 'HIGHLIGHTED_STATE',
  nodeFilterOptions: [
    'Person',
    'Organization',
    'Education',
    'Fund',
    'Engagement',
  ],
};

export const employersView = {
  familyOptions: [],
  professionalOptions: [
    'Prospect-Prospect Manager',
    'Prospect-Primary Plan Manager',
    'Prospect-Secondary Solicitor',
  ],
  organizationalOptions: [
    'Employer-Employee',
    'Colleague',
    'Employer-Postdoctoral Researcher',
    'Service Provider-Client',
    'Collaborator',
    'Instructor/Advisor-Student',
    'Postdoctoral Supervisor-Postdoctoral Researcher',
    'Power of Attorney-Client',
    'Organization-Branch Office',
    'Associated Company',
    'Successor Company-Defunct Company',
    'Joint Venture',
    'Prospect-Interaction Owner',
  ],
  charitableOptions: [],
  educationOptions: [],
  engagementOptions: [],
  organizationalOptionsEnabled: true,
  organizationalFilterType: 'HIGHLIGHTED_STATE',
  professionalFilterType: 'HIGHLIGHTED_STATE',
  professionalOptionsEnabled: true,
  nodeFilterOptionsEnabled: true,
  nodeFilterType: 'HIGHLIGHTED_STATE',
  nodeFilterOptions: [
    'Person',
    'Organization',
    'Education',
    'Fund',
    'Engagement',
  ],
};

export const viewFilterMap = {
  'Family-Interests': familyInterestsFiltersView,
  Colleagues: colleaguesFiltersView,
  Classmates: classmatesFiltersView,
  All: initialEdgeFilters,
  'Advancement-Connections': advancedConnectionsView,
  HighestEngagement: engagementView,
  LargestEmployers: employersView,
  TopPhilanthropicInterests: topPhilantrophicView,
  TopRegion25LargestEmployers: employersView,
  TopRegion25LargestFunds: topPhilantrophicView,
  TopRegion25LargestEngagement: engagementView,
};
