import React from "react";
import { Range, getTrackBackground } from "react-range";
import { MdArrowLeft, MdArrowRight } from 'react-icons/md';
import styles from './slider.module.css';

const SingleSlider = ({ min, max, step, setValues, values }) => {
  if(values[0] === undefined) {
    values = [0];
  }

  const handleChange = (value) => {
    setValues(value);
  };

  const renderTrack = (props) => {
    return <SliderTrack {...props} min={min} max={max} values={values} />;
  };

  return (
    <Range      
      values={values}
      step={step}
      min={min}
      max={max}
      onChange={handleChange}
      renderTrack={renderTrack}
      renderThumb={SliderThumb}
    />
  );
};

SingleSlider.defaultProps = {
  min: 0,
  max: 1000000,
  step: 10,
};

export default SingleSlider;

const SliderTrack = ({ props, children, min, max, values }) => {
  const colors = ["#D6D6D6", "#D6D6D6"];
  const background = getTrackBackground({
    values,
    min,
    max,
    colors
  });

  return (
    <div
      className={styles.slider_track_container}
      onMouseDown={props.onMouseDown}
      onTouchStart={props.onTouchStart}
      style={{ ...props.style, background }}
    >
      <div className={styles.slider_track} ref={props.ref}>
        {children}
      </div>
    </div>
  );
};


const SliderThumb = ({ props, isDragged, index }) => {
  return (
    <div className={styles.slider_thumb_container} style={props.style} {...props}>
      <MdArrowLeft className={styles.icon} />
      <MdArrowRight className={styles.icon_right} />
    </div>
  );
};