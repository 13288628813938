import { UNSELECTED_STATE, viewFilterMap } from 'store/filters/constants';

export const getViewFilters = (moreThanOneContext, viewType, state) => {
  const {
    familyOptions,
    professionalOptions,
    organizationalOptions,
    charitableOptions,
    educationOptions,
    engagementOptions,
    ...newEdgeState
  } = viewFilterMap[viewType];
  const newOptions = {
    familyOptions,
    professionalOptions,
    organizationalOptions,
    charitableOptions,
    educationOptions,
    engagementOptions,
  };
  const newSeparationValue = viewType === 'All' ? 1 : 2;
  const newViewFilterUpdates =
    viewType === 'All'
      ? 'All'
      : moreThanOneContext
      ? {
          familyOptions: [...state.familyOptions, ...familyOptions],
          professionalOptions: [
            ...state.professionalOptions,
            ...professionalOptions,
          ],
          organizationalOptions: [
            ...state.organizationalOptions,
            ...organizationalOptions,
          ],
          charitableOptions: [...state.charitableOptions, ...charitableOptions],
          educationOptions: [...state.educationOptions, ...educationOptions],
          engagementOptions: [...state.familyOptions, ...engagementOptions],
          ...newEdgeState,
        }
      : {
          familyOptions: [],
          professionalOptions: [],
          organizationalOptions: [],
          charitableOptions: [],
          educationOptions: [],
          engagementOptions: [],
          familyOptionsEnabled: false,
          professionalOptionsEnabled: false,
          organizationalOptionsEnabled: false,
          charitableOptionsEnabled: false,
          educationOptionsEnabled: false,
          engagementOptionsEnabled: false,
          familyFilterType: UNSELECTED_STATE,
          professionalFilterType: UNSELECTED_STATE,
          organizationalFilterType: UNSELECTED_STATE,
          charitableFilterType: UNSELECTED_STATE,
          educationFilterType: UNSELECTED_STATE,
          engagementFilterType: UNSELECTED_STATE,
          ...newOptions,
          ...newEdgeState,
        };

  if (moreThanOneContext) {
    return {
      ...state,
      familyOptions: [...state.familyOptions, ...familyOptions],
      professionalOptions: [
        ...state.professionalOptions,
        ...professionalOptions,
      ],
      organizationalOptions: [
        ...state.organizationalOptions,
        ...organizationalOptions,
      ],
      charitableOptions: [...state.charitableOptions, ...charitableOptions],
      educationOptions: [...state.educationOptions, ...educationOptions],
      engagementOptions: [...state.familyOptions, ...engagementOptions],
      ...newEdgeState,
      viewFilterUpdates: newViewFilterUpdates,
      degreesOfSeparation: newSeparationValue,
      loadingViewForContext: {},
    };
  } else {
    return {
      ...state,
      familyOptions: [],
      professionalOptions: [],
      organizationalOptions: [],
      charitableOptions: [],
      educationOptions: [],
      engagementOptions: [],
      familyOptionsEnabled: false,
      professionalOptionsEnabled: false,
      organizationalOptionsEnabled: false,
      charitableOptionsEnabled: false,
      educationOptionsEnabled: false,
      engagementOptionsEnabled: false,
      familyFilterType: UNSELECTED_STATE,
      professionalFilterType: UNSELECTED_STATE,
      organizationalFilterType: UNSELECTED_STATE,
      charitableFilterType: UNSELECTED_STATE,
      educationFilterType: UNSELECTED_STATE,
      engagementFilterType: UNSELECTED_STATE,
      viewFilterUpdates: newViewFilterUpdates,
      degreesOfSeparation: newSeparationValue,
      loadingViewForContext: {},
      ...newOptions,
      ...newEdgeState,
    };
  }
};
