import React from 'react';
import PropTypes from 'prop-types';
import styles from './imgContainer.module.css';

const Img = ({ src, size }) => {
    const style = {
        width: size,
        height: size
    };
    return (
        <div className={styles.img_wrapper} style={style}>
            {src && <img src={src} alt='img' className={styles.img} />}
        </div>
    );
}

Img.propTypes = {
    src: PropTypes.string,
    size: PropTypes.string,
}

export default Img;