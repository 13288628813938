import {
  charitableOptions,
  familyOptions,
  organizationalOptions,
  professionalOptions,
} from 'common/edge-filter-cards/options';
import {
  RELATIONSHIP_TYPE_CHARITABLE,
  RELATIONSHIP_TYPE_EDUCATION,
  RELATIONSHIP_TYPE_ENGAGEMENT,
  RELATIONSHIP_TYPE_FAMILY,
  RELATIONSHIP_TYPE_ORGANIZATIONAL,
  RELATIONSHIP_TYPE_PERSONAL,
} from 'store/graph/constants';
import {
  baseEdgeColor,
  baseNodeColor,
  edgeColors,
  nodeColors,
  nodeTextColor,
  nodeType,
  totalConnectionsFilterMax,
} from './constants';
import { currencyFormatter } from './formatters/currency';

export const getItems = (
  queryResponse,
  selectedNodeId,
  isCustomView = false,
  isRegionSearch = false,
) => {
  const { edges, vertices } = queryResponse;
  if (edges !== undefined && vertices !== undefined) {
    const { nodes, nodeCount } = makeNodes(vertices, selectedNodeId);
    const links = makeLinks(edges, nodes);
    if (isCustomView && !isRegionSearch) {
      const updatedNodes = setupTraversalProperty(nodes, links, selectedNodeId);
      return { nodes: updatedNodes, links, nodeCount };
    }
    return { nodes, links, nodeCount };
  }
};

const makeNodes = (vertices, selectedNodeId) => {
  let nodes = {};
  let nodeCount = 0;
  for (const key in vertices) {
    const vertex = vertices[key];
    const label = vertex.label.toLowerCase();
    const node = getNode(label, vertex, key, selectedNodeId);
    // if item is not empty, set it
    nodes[key] = node;
    nodeCount++;
  }
  return { nodes, nodeCount };
};

const makeLinks = (edges, nodes) => {
  let links = {};
  for (const key in edges) {
    const edge = edges[key];
    const { inV, outV } = edge;
    // if (edge.properties.relationshipcombo.toLowerCase().includes('matching')) {
    //   console.log(edge);
    // }
    // remove links with nodes that do not exist
    if (nodes[inV] && nodes[outV]) {
      const {
        connection,
        connectionText,
        topNodeRelationshipType,
        bottomNodeRelationshipType,
        activeColor,
      } = getConnectionType(edge.properties, nodes, inV, outV);
      links[`${edge.inV}/p/${edge.outV}`] = {
        id1: edge.inV,
        id2: edge.outV,
        width: 5,
        color: baseEdgeColor,
        activeColor,
        data: {
          id: edge.id,
          inV: edge.inV,
          label: edge.label,
          outV: edge.outV,
          ...edge.properties,
          connection,
          connectionType: connectionText,
          bottomNodeRelationshipType,
          topNodeRelationshipType,
        },
      };
    }
  }
  return links;
};

const getNode = (label, vertex, nodeKey, selectedContextId) => {
  let item = {};
  //TODO: CONTEXT IS BEING OVEDRWRITTEN IF IT ALREADY EXISTS IN RESPONSE
  if (label === 'person') {
    const {
      firstname,
      traversal,
      total_first_connections,
      MetroRegion,
      crmurl,
      rating,
      maritalstatus,
      age,
      gender,
      primaryaffiliation,
      lifetimehouseholdgiving,
      maidenname,
      uiucltg,
      uisltg,
      uicltg,
      constituency,
      isdeceased,
      keyname,
    } = vertex.properties;
    const name = `${firstname} ${keyname}`;
    let selected = {};
    if (nodeKey === selectedContextId) {
      selected = {
        color: nodeColors.contextNode,
        border: { color: nodeColors.person, width: 5 },
        label: { bold: true, color: nodeTextColor, text: name },
        halos: [{ color: nodeColors.contextNode, radius: 33, width: 4 }],
        size: 8,
        isContextNode: true,
      };
    }
    const { mostConnected, highestCapacity, largestDonor } = getSizingTypes(
      total_first_connections,
      lifetimehouseholdgiving,
      rating,
    );
    item = {
      id: nodeKey,
      isContextNode: false,
      ...getNodeItem(name, mostConnected),
      ...selected,
      cutout: true,
      traversal,
      nodeType: nodeType.Person,
      data: {
        ...vertex.properties,
        name: name,
        age: age,
        constituency: constituency,
        gender: gender,
        maiden: maidenname,
        maritalStatus: maritalstatus,
        affliationUnit: primaryaffiliation,
        totalConnections: total_first_connections,
        prospectStatus: rating,
        lifetimeGiving: lifetimehouseholdgiving,
        region: MetroRegion,
        mostConnectedSize: mostConnected,
        highestCapacitySize: highestCapacity,
        largestDonorSize: largestDonor,
        tedUrl: crmurl,
        isdeceased: isdeceased,
        snapshot: {
          total: currencyFormatter(lifetimehouseholdgiving),
          universityGiving: [
            {
              name: 'UIUC',
              amount: currencyFormatter(uiucltg),
            },
            {
              name: 'UIC',
              amount: currencyFormatter(uicltg),
            },
            {
              name: 'UIS',
              amount: currencyFormatter(uisltg),
            },
          ],
        },
        nodeType: nodeType.Person,
        nodeKey,
      },
    };
  } else if (label === 'org') {
    let selected = {};
    const {
      industry,
      traversal,
      total_first_connections,
      fullname,
      crmurl,
      website,
      uiucltg,
      uisltg,
      uicltg,
      constituency,
      MetroRegion,
      lifetimehouseholdgiving,
    } = vertex.properties;
    if (nodeKey === selectedContextId) {
      selected = {
        color: nodeColors.contextNode,
        border: { color: nodeColors.organization, width: 5 },
        label: { bold: true, color: nodeTextColor, text: fullname },
        halos: [{ color: nodeColors.contextNode, radius: 33, width: 4 }],
        size: 8,
        isContextNode: true,
      };
    }
    const { mostConnected, highestCapacity, largestDonor } = getSizingTypes(
      total_first_connections,
      lifetimehouseholdgiving,
      undefined,
    );
    item = {
      id: nodeKey,
      isContextNode: false,
      ...getNodeItem(fullname, mostConnected),
      ...selected,
      cutout: true,
      traversal,
      nodeType: nodeType.Organization,
      data: {
        ...vertex.properties,
        industry: industry,
        name: fullname,
        organizationType: '',
        totalConnections: total_first_connections,
        website: website,
        constituency: constituency,
        tedUrl: crmurl,
        region: MetroRegion,
        nodeKey,
        mostConnectedSize: mostConnected,
        highestCapacitySize: highestCapacity,
        largestDonorSize: largestDonor,
        snapshot: {
          total: currencyFormatter(lifetimehouseholdgiving),
          universityGiving: [
            {
              name: 'UIUC',
              amount: currencyFormatter(uiucltg),
            },
            {
              name: 'UIC',
              amount: currencyFormatter(uicltg),
            },
            {
              name: 'UIS',
              amount: currencyFormatter(uisltg),
            },
          ],
        },
        nodeType: nodeType.Organization,
      },
    };
  } else if (label === 'fund') {
    const {
      total_first_connections,
      traversal,
      departmentdescription,
      crmurl,
      fundname,
      institutiondescription,
      purpose,
      collegedescription,
      MetroRegion,
    } = vertex.properties;
    let selected = {};
    if (nodeKey === selectedContextId) {
      selected = {
        color: nodeColors.contextNode,
        label: {
          bold: true,
          color: nodeTextColor,
          backgroundColor: 'rgba(0,0,0,0)',
          text: fundname,
        },
        border: { color: nodeColors.fund, width: 5 },
        halos: [{ color: nodeColors.contextNode, radius: 33, width: 4 }],
        size: 8,
        isContextNode: true,
      };
    }
    const { mostConnected } = getSizingTypes(
      total_first_connections,
      undefined,
      undefined,
    );
    item = {
      id: nodeKey,
      isContextNode: false,
      ...getNodeItem(fundname, mostConnected),
      ...selected,
      cutout: true,
      nodeType: nodeType.Fund,
      traversal,
      data: {
        ...vertex.properties,
        department: departmentdescription,
        totalConnections: total_first_connections,
        fundName: fundname,
        name: fundname,
        nodeKey,
        institution: institutiondescription,
        region: MetroRegion,
        purpose: purpose,
        college: collegedescription,
        nodeType: nodeType.Fund,
        mostConnectedSize: mostConnected,
        highestCapacitySize: 1,
        largestDonorSize: 1,
        tedUrl: crmurl,
      },
    };
  } else if (label === 'education') {
    const {
      title,
      traversal,
      total_first_connections,
      crmurl,
      attribute4,
      attribute3,
      attribute2,
      attribute1,
      MetroRegion,
      titlecode,
    } = vertex.properties;
    const text = title || titlecode;
    let selected = {};
    if (nodeKey === selectedContextId) {
      selected = {
        color: nodeColors.contextNode,
        border: { color: nodeColors.education, width: 5 },
        label: {
          bold: true,
          color: nodeTextColor,
          backgroundColor: 'rgba(0,0,0,0)',
          text,
        },
        halos: [{ color: nodeColors.contextNode, radius: 33, width: 4 }],
        size: 8,
        isContextNode: true,
      };
    }
    const { mostConnected } = getSizingTypes(
      total_first_connections,
      undefined,
      undefined,
    );
    item = {
      id: nodeKey,
      isContextNode: false,
      ...getNodeItem(text, mostConnected),
      ...selected,
      cutout: true,
      nodeType: nodeType.Education,
      traversal,
      data: {
        ...vertex.properties,
        totalConnections: total_first_connections,
        institution: attribute1,
        region: MetroRegion,
        college: attribute2,
        department: attribute3,
        major: attribute4,
        name: text,
        nodeKey,
        mostConnectedSize: mostConnected,
        highestCapacitySize: 1,
        largestDonorSize: 1,
        nodeType: nodeType.Education,
        tedUrl: crmurl,
      },
    };
  } else if (label === 'engagement') {
    const {
      fullname,
      traversal,
      total_first_connections,
      crmurl,
      grouptype,
      description,
      MetroRegion,
    } = vertex.properties;
    const title = fullname;
    let selected = {};
    if (nodeKey === selectedContextId) {
      selected = {
        color: nodeColors.contextNode,
        border: { color: nodeColors.engagement, width: 5 },
        label: {
          bold: true,
          color: nodeTextColor,
          backgroundColor: 'rgba(0,0,0,0)',
          text: title,
        },
        halos: [{ color: nodeColors.contextNode, radius: 33, width: 4 }],
        size: 8,
        isContextNode: true,
      };
    }
    const { mostConnected } = getSizingTypes(
      total_first_connections,
      undefined,
      undefined,
    );
    item = {
      id: nodeKey,
      isContextNode: false,
      ...getNodeItem(title, mostConnected),
      ...selected,
      cutout: true,
      traversal,
      nodeType: nodeType.Engagement,
      data: {
        ...vertex.properties,
        totalConnections: total_first_connections,
        groupType: grouptype,
        region: MetroRegion,
        committeeType: description,
        name: title,
        nodeKey,
        mostConnectedSize: mostConnected,
        highestCapacitySize: 1,
        largestDonorSize: 1,
        nodeType: nodeType.Engagement,
        tedUrl: crmurl,
      },
    };
  }
  return item;
};

// top relaation ties to outnode, bottom relationship ties inNode
const getConnectionType = (properties, nodes, inNodeId, outNodeId) => {
  const { relationshipcombo } = properties;
  // is a non normal edge split
  const isNotNormalEdge = isNonNormalEdge(relationshipcombo);
  // relationship combo gets split
  const [topRelationshipType, bottomRelationshipType] = !isNotNormalEdge
    ? relationshipcombo.split(/[-]+/)
    : [relationshipcombo, relationshipcombo];
  const inNode = nodes[inNodeId]?.data;
  const outNode = nodes[outNodeId]?.data;

  let connectionText = '';
  let activeColor = '';
  // TODO: check the dictionary types and set. and make colors a constant that be changed from one place
  if (!inNode || !outNode)
    return { connectionText, topNode: {}, bottomNode: {} };
  if (familyOptions[relationshipcombo] !== undefined) {
    connectionText = RELATIONSHIP_TYPE_FAMILY;
    activeColor = edgeColors.family;
  } else if (professionalOptions[relationshipcombo] !== undefined) {
    connectionText = RELATIONSHIP_TYPE_PERSONAL;
    activeColor = edgeColors.personal;
  } else if (organizationalOptions[relationshipcombo] !== undefined) {
    connectionText = RELATIONSHIP_TYPE_ORGANIZATIONAL;
    activeColor = edgeColors.professional;
  } else if (
    inNode.nodeType === nodeType.Fund ||
    outNode.nodeType === nodeType.Fund ||
    charitableOptions[relationshipcombo] !== undefined
  ) {
    connectionText = RELATIONSHIP_TYPE_CHARITABLE;
    activeColor = edgeColors.charitable;
  } else if (
    inNode.nodeType === nodeType.Education ||
    outNode.nodeType === nodeType.Education
  ) {
    connectionText = RELATIONSHIP_TYPE_EDUCATION;
    activeColor = edgeColors.education;
  } else if (
    inNode.nodeType === nodeType.Engagement ||
    outNode.nodeType === nodeType.Engagement
  ) {
    connectionText = RELATIONSHIP_TYPE_ENGAGEMENT;
    activeColor = edgeColors.engagement;
  }

  return {
    connectionText,
    connection: relationshipcombo,
    topNodeRelationshipType: topRelationshipType,
    bottomNodeRelationshipType: bottomRelationshipType,
    activeColor,
  };
};

export const getSizingTypes = (
  totalConnections,
  lifetimeGiving,
  prospectStatus,
) => {
  let mostConnected = getSizeMostConnected(totalConnections);
  let highestCapacity = getSizeProspectStatus(prospectStatus);
  let largestDonor = getSizeLargestDonor(lifetimeGiving);
  return { mostConnected, highestCapacity, largestDonor };
};

export const getSizeMostConnected = (totalConnections) => {
  if (!totalConnections || totalConnections <= 10) {
    return 1;
  }
  if (totalConnections <= 50) {
    return 2;
  }
  if (totalConnections <= 100) {
    return 3;
  }
  if (totalConnections <= 500) {
    return 4;
  }
  if (totalConnections <= 1000) {
    return 6;
  }
  if (totalConnections > 1000) {
    return 8;
  }
};

export const sizeProspectMap = {
  '< $2,500': 1,
  '$2,500 - $4,999': 1,
  '$5,000 - $9,999': 1,
  '$10,000 - $24,999': 2,
  '$25,000 - $49,999': 2,
  '$50,000 - $99,999': 3,
  '$100,000 - $249,999': 3,
  '$250,000 - $499,999': 4,
  '$500,000 - $999,999': 4,
  '$1,000,000 - $4,999,999': 5,
  '$5,000,000 -$9,999,999': 5,
  '$10,000,000 - $24,999,999': 6,
  '$25,000,000 - $49,999,999': 6,
  '$50,000,000 - $99,999,999': 7,
  '$100,000,000 - $249,999,999': 7,
  '$250,000,000+': 8,
};

export const getSizeProspectStatus = (prospectStatus) => {
  if (!prospectStatus) {
    return 1;
  }
  return sizeProspectMap[prospectStatus] || 1;
};

export const getSizeLargestDonor = (lifetimeGiving) => {
  if (!lifetimeGiving || lifetimeGiving < 10000) {
    return 1;
  }
  if (lifetimeGiving < 50000) {
    return 2;
  }
  if (lifetimeGiving < 250000) {
    return 3;
  }
  if (lifetimeGiving < 1000000) {
    return 4;
  }
  if (lifetimeGiving < 50000000) {
    return 5;
  }
  if (lifetimeGiving < 250000000) {
    return 6;
  }
  if (lifetimeGiving >= 250000000) {
    return 8;
  }
};

export const getSize = (nodeSizingType, properties) => {
  let size = properties.mostConnectedSize;
  if (nodeSizingType === 1) {
    size = properties.highestCapacitySize;
  }
  if (nodeSizingType === 2) {
    size = properties.largestDonorSize;
  }
  return size;
};

export const getNodeItem = (text, size) => {
  return {
    color: baseNodeColor,
    border: { color: baseNodeColor },
    label: {
      text,
      color: nodeTextColor,
      fontSize: 9,
      center: true,
    },
    size,
  };
};

export const GetNodeSelectionStyles = (node) => {
  const { color, text } = getNodeColorAndText(node);
  return {
    color: color,
    border: { color: '#fff', width: 2 },
    label: {
      bold: true,
      color: nodeTextColor,
      backgroundColor: 'rgba(0,0,0,0)',
      text: text,
    },
    halos: [{ color: color, radius: 30, width: 2 }],
  };
};
export const GetNodeHoverStyles = (node) => {
  const { color, text } = getNodeColorAndText(node);
  return {
    color: color,
    border: { color: '#fff', width: 2 },
    label: {
      bold: true,
      color: nodeTextColor,
      backgroundColor: 'rgba(0,0,0,0)',
      text: text,
    },
    halos: [{ color: color, radius: 30, width: 2 }],
  };
};

export const GetBaseNodeStyles = (node, nodeSizingType) => {
  const size = getSize(nodeSizingType, node.data);
  const text = node.data.name;
  return {
    color: baseNodeColor,
    border: { color: baseNodeColor },
    label: {
      text: text,
      color: nodeTextColor,
      fontSize: 9,
      center: true,
    },
    halos: [],
    size,
  };
};

export const getNodeColor = (type) => {
  switch (type) {
    case nodeType.Person:
      return nodeColors.person;
    case nodeType.Education:
      return nodeColors.education;
    case nodeType.Fund:
      return nodeColors.fund;
    case nodeType.Engagement:
      return nodeColors.engagement;
    case nodeType.Organization:
      return nodeColors.organization;
    default:
      return nodeColors.person;
  }
};

export const getNodeColorAndText = (node) => {
  const color = getNodeColor(node.nodeType);
  return { color, text: node?.data?.name || '' };
};

export const isLink = (item) => {
  return item.id1 !== undefined;
};

const NonNormalEdges = {
  'in-law': 'in-law',
};

const isNonNormalEdge = (edge) => {
  return NonNormalEdges[edge.toLowerCase()] !== undefined;
};

export const IsObjectEmpty = (obj) => {
  for (const i in obj) {
    return false;
  }
  return true;
};

// add node helper
export const canAddNode = (
  node,
  isRegionsFilterEmpty,
  isConstituencyEmpty,
  constitencies,
  nodeFilters,
  hiddenNodes = {},
) => {
  if (hiddenNodes[node?.id]) {
    return false;
  }
  const {
    region,
    affiliation,
    lifetimeGiving,
    prospectStatus,
    totalConnections,
    degreeOfSeparation,
    showDeceasedConnections,
  } = nodeFilters;
  return (
    canAddRegion(node, region, isRegionsFilterEmpty) &&
    canAddDegrees(node, degreeOfSeparation) &&
    canAddProspect(node, prospectStatus) &&
    canAddTotalConnections(node, totalConnections) &&
    canAddTotalLifetimeGiving(node, lifetimeGiving) &&
    canAddAffiliation(node, affiliation) &&
    canAddConstituency(node, constitencies, isConstituencyEmpty) &&
    canAddDeceased(node, showDeceasedConnections)
  );
};

export const canAddProspect = (item, prospectFilter) => {
  if (prospectFilter.length < 1) return true;
  if (!item) return false;
  var type = item.data.nodeType;
  if (type !== nodeType.Person && type !== nodeType.Organization) return false;
  let prospectStatus = '';
  if (type === nodeType.Person) {
    prospectStatus = item.data?.prospectStatus;
  } else {
    prospectStatus = item.data.rating;
  }
  return prospectFilter.includes(prospectStatus);
};

export const canAddRegion = (item, regionFilters, isRegionsFilterEmpty) => {
  if (!item) return false;
  if (isRegionsFilterEmpty) return true;
  return regionFilters[item.data.MetroRegion] !== undefined;
};

export const canAddDegrees = (item, degree) => {
  if (!item) return false;
  if (!degree || degree >= 3) return true;
  if (item.traversal === undefined) return false;
  return item.traversal <= degree;
};

export const canAddAffiliation = (item, affiliation) => {
  if (!item) return false;
  if (!affiliation) return true;
  if (item.data.nodeType !== nodeType.Person) return false;
  return (
    item?.data?.affliationUnit &&
    item?.data?.affliationUnit.toLowerCase().includes(affiliation)
  );
};
export const canAddConstituency = (
  item,
  constitencies,
  isConstituencyEmpty,
) => {
  if (!item) return false;
  if (isConstituencyEmpty) return true;
  if (
    item.data.nodeType !== nodeType.Person &&
    item.data.nodeType !== nodeType.Organization
  )
    return false;
  const constit = item?.data?.constituency.toLowerCase();
  let y = false;
  constitencies.forEach((x) => {
    if (constit.includes(x.toLowerCase())) {
      y = true;
    }
  });
  return y;
};

export const canAddTotalConnections = (item, connections) => {
  if (!item) return false;
  if (connections < 1 || connections >= totalConnectionsFilterMax) return true;
  return item.data.totalConnections >= connections;
};

export const canAddDeceased = (item, showDeceased) => {
  if (!item) return false;
  if (item.data.nodeType !== nodeType.Person || showDeceased) return true;
  return !item.data.isdeceased;
};

export const canAddTotalLifetimeGiving = (item, range) => {
  if (!item) return false;
  const { min, max } = range;
  if (min === undefined && max === undefined) {
    return true;
  }
  if (
    item.data.nodeType !== nodeType.Person &&
    item.data.nodeType !== nodeType.Organization
  ) {
    return false;
  }
  const {
    data: { lifetimeGiving },
  } = item;
  if (min !== undefined && lifetimeGiving < min) {
    return false;
  }
  if (max !== undefined && lifetimeGiving > max) {
    return false;
  }
  return true;
};

export const setupTraversalProperty = (nodes, links, selectedNodeId) => {
  let items = {};
  // add selectedNode
  const selectedNode = nodes[selectedNodeId];
  items[selectedNodeId] = selectedNode;

  const linksForLoop = { ...links };
  const nodesOnFirstLevel = getConnectedNodes(
    selectedNodeId,
    items,
    nodes,
    linksForLoop,
  );

  let nodesOnSecondLevel = [];
  nodesOnFirstLevel.forEach((node) => {
    const nextLevel = getConnectedNodes(node.id, items, nodes, linksForLoop);
    nodesOnSecondLevel.push(...nextLevel);
    items[node.id] = { ...node, traversal: 1 };
  });

  let nodesOnThirdLevel = [];
  nodesOnSecondLevel.forEach((node) => {
    const nextLevel = getConnectedNodes(node.id, items, nodes, linksForLoop);
    nodesOnThirdLevel.push(...nextLevel);
    if (items[node.id] === undefined) {
      items[node.id] = { ...node, traversal: 2 };
    }
  });

  nodesOnThirdLevel.forEach((node) => {
    if (items[node.id] === undefined) {
      items[node.id] = { ...node, traversal: 3 };
    }
  });

  return items;
};

const getConnectedNodes = (nodeId, items, nodes, links) => {
  let nodesOnNextLevel = [];
  for (const linkId in links) {
    if (linkId.includes(nodeId)) {
      const [nodeOneId, nodeTwoId] = linkId.split('/p/');
      if (nodeOneId === nodeId) {
        // add nodeTwo
        if (items[nodeTwoId] === undefined) {
          nodesOnNextLevel.push(nodes[nodeTwoId]);
        }
      } else {
        if (items[nodeOneId] === undefined) {
          nodesOnNextLevel.push(nodes[nodeOneId]);
        }
      }
      delete links[linkId];
    }
  }
  return nodesOnNextLevel;
};
