import React from 'react';
import PropTypes from 'prop-types';
import { BsChevronRight } from 'react-icons/bs';
import CardHeader from '../../card-header';
import Img from '../../img-container';
import Input from '../../input';
import styles from './engagementNodeCard.module.css';
import ButtonSectionCard from '../../button-section-card';
import { useDispatch, useSelector } from 'react-redux';
import { setSearch } from 'store/graph/actions';
import ReactTooltip from 'react-tooltip';
import { EngagementIcon } from 'common/icon/icon';
import { selectIsContextIdsEmpty } from 'store/graph/selectors';
import LastUpdated from 'common/last-updated';

const EngagementCard = ({ properties, moreInformation }) => {
  const dispatch = useDispatch();
  const showShortestPath = useSelector(selectIsContextIdsEmpty);
  const {
    name,
    imgSrc = '',
    committeeType,
    groupType,
    tedUrl,
    nodeKey,
    region,
    ingestionDateUtc,
  } = properties;
  const rightClick = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    moreInformation();
  };

  const setAsAContext = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(
      setSearch({
        displayValue: name,
        id: nodeKey,
        metroRegion: region,
        label: 'org',
        type: 'vertex',
        value: name,
      }),
    );
  };

  return (
    <div className={styles.card}>
      <CardHeader
        showShortestPath={!showShortestPath}
        value="Engagement"
        icon={<EngagementIcon />}
        nodeId={nodeKey}
        properties={properties}
      />
      <div className={styles.content}>
        <div className={styles.content_header}>
          <div className={styles.left_section}>
            <div className={styles.name}>
              <h2 className={styles.name_desc}>{name}</h2>
              <BsChevronRight
                data-tip
                data-for={nodeKey}
                className={styles.name_icon}
                onClick={setAsAContext}
              />
              <ReactTooltip id={nodeKey} place="top" effect="solid">
                Add as a search context
              </ReactTooltip>
            </div>
          </div>
          <div className={styles.right_section}>
            <Img src={imgSrc} size="64px" />
          </div>
        </div>
        <div className={styles.giving_prospect_status}>
          <div className={styles.split_wrapper}>
            <Input value={groupType} label="Group Type" />
          </div>
          <div className={styles.split_wrapper}></div>
        </div>
        <div className={styles.affiliation_unit}>
          <Input value={committeeType} label="Committee Type" />
        </div>
        <LastUpdated ingestionDateUtc={ingestionDateUtc} />
      </div>
      <ButtonSectionCard
        tedUrl={tedUrl}
        icon={<BsChevronRight className={styles.button_right_icon} />}
        text="More Information"
        rightClick={rightClick}
      />
    </div>
  );
};

EngagementCard.propTypes = {
  properties: PropTypes.object,
  moreInformation: PropTypes.object,
};

export default EngagementCard;
