import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setMaxLifetimeGiving,
  setMinLifetimeGiving,
} from 'store/filters/actions';
import {
  selectTotalLifetimeGivingMax,
  selectTotalLifetimeGivingMin,
} from 'store/filters/selectors';
import { currencyFormatter } from 'utils/formatters/currency';
import DropDown from '../../drop-down';
import styles from './lifetime.module.css';
import debounceFunction from 'common/search-input/debounce';
import ReactTooltip from 'react-tooltip';

const LifetimeGivingFilter = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const min = useSelector(selectTotalLifetimeGivingMin);
  const max = useSelector(selectTotalLifetimeGivingMax);
  const [minEdit, setMinEdit] = useState(false);
  const [maxEdit, setMaxEdit] = useState(false);
  const [error, setError] = useState('');
  const handleToggle = () => {
    setOpen(!open);
  };

  const setMinEditTrue = () => {
    setMinEdit(true);
  };
  const stopMinEdit = () => {
    setMinEdit(false);
    setError('');
  };
  const setMaxValue = (value) => {
    dispatch(setMaxLifetimeGiving(value));
  };

  const setMaxEditTrue = () => {
    setMaxEdit(true);
  };
  const stopMaxEdit = () => {
    setMaxEdit(false);
    setError('');
  };
  const setMinValue = (value) => {
    dispatch(setMinLifetimeGiving(value));
  };

  // const maxCurr = maxLocal && !isNaN(maxLocal) ? currencyFormatter(maxLocal) : '';
  return (
    <div className={!open ? 'filter_by_wrapper' : 'filter_by_open_wrapper'}>
      <DropDown
        filterText="Total lifetime giving"
        onClick={handleToggle}
        open={open}
      />
      {open && (
        <div className={styles.open_region}>
          <div className={styles.input_region}>
            <EditInput
              title="Min"
              max={max}
              min={min}
              isEdit={minEdit}
              setEditTrue={setMinEditTrue}
              close={stopMinEdit}
              setValue={setMinValue}
              setError={setError}
            />
            <EditInput
              title="Max"
              max={max}
              min={min}
              isEdit={maxEdit}
              setEditTrue={setMaxEditTrue}
              close={stopMaxEdit}
              setValue={setMaxValue}
              setError={setError}
            />
          </div>
          <div className={styles.error}>{error}</div>
        </div>
      )}
    </div>
  );
};

const EditInput = ({
  title,
  max,
  min,
  setValue,
  isEdit,
  setEditTrue,
  close,
  setError,
}) => {
  const value = title === 'Min' ? min : max;
  const [inputValue, setInputValue] = useState(value);
  const handleSave = (val) => {
    if (val === '') {
      setValue(undefined);
      setInputValue('');
      setError('');
      close();
    } else {
      const validNumber = validateNumber(val);
      if (!validNumber) {
        setError('Please enter in a valid number!');
      } else {
        setValue(validNumber);
        setInputValue('');
        setError('');
        close();
      }
    }
  };

  const setDebouncedValue = (val) => {
    handleSave(val);
  };

  const debounceDropDown = useCallback(
    debounceFunction((nextValue) => setDebouncedValue(nextValue), 1000),
    [],
  );

  const onChange = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    const value = e.target.value;
    setInputValue(value);
    debounceDropDown(value);
  };

  const handleBlur = () => {
    if (inputValue === '' || inputValue === value) {
      close();
    }
  };

  const handleFocus = () => {
    setInputValue(value);
  };

  const valueCurrency = value ? currencyFormatter(value) : '';
  let content = (
    <>
      <p className={styles.option}>{title}</p>
      <div className={styles.input} data-tip data-for={`liftime-id-${title}`}>
        {valueCurrency}
      </div>
      {valueCurrency && (
        <ReactTooltip id={`liftime-id-${title}`} place="top" effect="solid">
          {valueCurrency}
        </ReactTooltip>
      )}
    </>
  );
  if (isEdit) {
    content = (
      <>
        <input
          autoFocus
          type="number"
          className={styles.input_edit}
          value={inputValue}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </>
    );
  }

  return (
    <div className={styles.input_wrapper} onClick={setEditTrue}>
      {content}
    </div>
  );
};

const validateNumber = (newValue) => {
  const val = Number(newValue);
  return isNaN(val) ? null : val;
};

export default LifetimeGivingFilter;
