import React from 'react';
import PropTypes from 'prop-types';
import { FiExternalLink } from 'react-icons/fi';
import styles from './buttonSection.module.css';

const ButtonSectionCard = ({ icon, text, rightClick, tedUrl, showTed=true, hideAll=false }) => {
    const handleNavigate = () => {
        if (tedUrl) window.open(tedUrl, '_blank');
    };

    return (
        <div className={styles.button_section}>
            {
                hideAll ?
                <div style={{ height: 'some height', width: 'some width' }}></div> :
                <>
                    {showTed && (
                        <div className={styles.button_wrapper} onClick={handleNavigate}>
                            <div className={styles.button_text} style={{ marginRight: '8px' }}>View in TED</div>
                            <FiExternalLink className={styles.button_right_icon} />
                        </div>
                    )}
                    {text && (
                        <div className={styles.button_wrapper} onClick={rightClick}>
                            <div className={styles.button_text}>{text}</div>
                            <div className={styles.button_right_icon}>{icon}</div>
                        </div>
                    )}
                </>
            }
        </div>
    );
}

ButtonSectionCard.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.node,
    rightClick: PropTypes.func,
    tedUrl: PropTypes.string,
    showTed: PropTypes.bool,
    hideAll: PropTypes.bool
}

export default ButtonSectionCard;
