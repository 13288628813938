import React from 'react';
import { nodeType } from 'utils/constants';
import EmployerCard from './cards/employer';
import FundRelationshipCard from './cards/fund';
import Person from './cards/person';
import Education from './education';

const Personal = ({topNode, bottomNode, topRelation, bottomRelation}) => {
    if(!bottomRelation)  bottomRelation = topRelation;

    let top =  <Person properties={topNode} headerText={topRelation} />;
    if(topNode.nodeType === nodeType.Person) {
        top = <Person properties={topNode} headerText={topRelation}/>;
    } 
    if(topNode.nodeType === nodeType.Organization) {
        top = <EmployerCard properties={topNode} headerText={topRelation}/>;
    } 
    if(topNode.nodeType === nodeType.Fund) {
        top = <FundRelationshipCard properties={topNode} headerText={topRelation}/>;
    } 
    if(topNode.nodeType === nodeType.Education) {
        top = <Education properties={topNode} headerText={topRelation}/>;
    }

    let bottom =  <Person properties={bottomNode} headerText={bottomRelation} />;
    if(topNode.nodeType === nodeType.Person) {
        bottom = <Person properties={bottomNode} headerText={bottomRelation}/>;
    } 
    if(topNode.nodeType === nodeType.Organization) {
        bottom = <EmployerCard properties={bottomNode} headerText={bottomRelation}/>;
    } 
    if(topNode.nodeType === nodeType.Fund) {
        bottom = <FundRelationshipCard properties={bottomNode} headerText={bottomRelation}/>;
    } 
    if(topNode.nodeType === nodeType.Education) {
        bottom = <Education properties={bottomNode} headerText={bottomRelation}/>;
    }

    return (
        <>
            {top}
            {bottom}
        </>
    );
}

export default Personal;
