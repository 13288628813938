import React from 'react';
import Employer from './cards/employer';
import Employee from './cards/employee';
import { nodeType } from 'utils/constants';

const Organizational = ({topNode, bottomNode, topRelation, bottomRelation}) => {
    if(!bottomRelation)  bottomRelation = topRelation;
    let top =  <Employer properties={topNode} headerText={topRelation} />;
    if(topNode.nodeType === nodeType.Person) {
        top = <Employee properties={topNode} headerText={topRelation}/>;
    } 
    if(topNode.nodeType === nodeType.Organization) {
        top = <Employer properties={topNode} headerText={topRelation}/>;
    } 
    if(topNode.nodeType === nodeType.Fund) {
        top = <Employer properties={topNode} headerText={topRelation}/>;
    } 
    if(topNode.nodeType === nodeType.Education) {
        top = <Employer properties={topNode} headerText={topRelation}/>;
    }

    let bottom =  <Employee properties={bottomNode} headerText={bottomRelation} />;
    if(bottomNode.nodeType === nodeType.Person) {
        bottom = <Employee properties={bottomNode} headerText={bottomRelation}/>;
    } 
    if(bottomNode.nodeType === nodeType.Organization) {
        bottom = <Employer properties={bottomNode} headerText={bottomRelation}/>;
    } 
    if(bottomNode.nodeType === nodeType.Fund) {
        bottom = <Employer properties={bottomNode} headerText={bottomRelation}/>;
    } 
    if(bottomNode.nodeType === nodeType.Education) {
        bottom = <Employer properties={bottomNode} headerText={bottomRelation}/>;
    }

    return (
        <>
            {top}
            {bottom}
        </>
    );
}

export default Organizational;
