import styles from './dob.module.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DOBInput = ({ value, inError, errorText, placeholderText, disabled, setValue }) => {
  return (
    <div className={styles.root} data-testid="dob-input">
      <DatePicker
        className={styles.datepicker}
        selected={value}
        placeholderText={placeholderText}
        dateFormat="MM/dd/yyyy"
        onChange={setValue}
        maxDate={new Date()}
        disabled={disabled}
        showMonthDropdown
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={120}
      />
      {inError && (
        <div className={styles.error_text} data-testid="dob-input-error-text">
          {errorText}
        </div>
      )}
    </div>
  );
};

export default DOBInput;