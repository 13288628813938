import { createSelector } from 'reselect';
import {
  HIGHLIGHTED_STATE,
  SELECTED_STATE,
  UNSELECTED_STATE,
} from './constants';

const filter = (state) => state.filters;

export const selectFilters = createSelector([filter], (state) => {
  const {
    familyOptions,
    professionalOptions,
    organizationalOptions,
    charitableOptions,
    educationOptions,
    engagementOptions,
    nodeFilterOptions,
    familyOptionsEnabled,
    professionalOptionsEnabled,
    organizationalOptionsEnabled,
    charitableOptionsEnabled,
    educationOptionsEnabled,
    engagementOptionsEnabled,
    nodeFilterOptionsEnabled,
    familyOptionsTimeFilter,
    professionalOptionsTimeFilter,
    organizationalOptionsTimeFilter,
    charitableOptionsTimeFilter,
    educationOptionsTimeFilter,
    engagementOptionsTimeFilter,
    nodeFilterOptionsTimeFilter,
    nodeFilterType,
    familyFilterType,
    professionalFilterType,
    organizationalFilterType,
    charitableFilterType,
    educationFilterType,
    engagementFilterType,
    nodeFilterOut,
    edgesFilterOut,
    showVisibleConnections,
  } = state;
  return JSON.stringify({
    familyOptions: JSON.stringify(familyOptions),
    professionalOptions: JSON.stringify(professionalOptions),
    organizationalOptions: JSON.stringify(organizationalOptions),
    charitableOptions: JSON.stringify(charitableOptions),
    educationOptions: JSON.stringify(educationOptions),
    engagementOptions: JSON.stringify(engagementOptions),
    nodeFilterOptions: JSON.stringify(nodeFilterOptions),
    familyOptionsEnabled: JSON.stringify(familyOptionsEnabled),
    professionalOptionsEnabled: JSON.stringify(professionalOptionsEnabled),
    organizationalOptionsEnabled: JSON.stringify(organizationalOptionsEnabled),
    charitableOptionsEnabled: JSON.stringify(charitableOptionsEnabled),
    educationOptionsEnabled: JSON.stringify(educationOptionsEnabled),
    engagementOptionsEnabled: JSON.stringify(engagementOptionsEnabled),
    nodeFilterOptionsEnabled: JSON.stringify(nodeFilterOptionsEnabled),
    familyOptionsTimeFilter: JSON.stringify(familyOptionsTimeFilter),
    professionalOptionsTimeFilter: JSON.stringify(
      professionalOptionsTimeFilter,
    ),
    organizationalOptionsTimeFilter: JSON.stringify(
      organizationalOptionsTimeFilter,
    ),
    charitableOptionsTimeFilter: JSON.stringify(charitableOptionsTimeFilter),
    educationOptionsTimeFilter: JSON.stringify(educationOptionsTimeFilter),
    engagementOptionsTimeFilter: JSON.stringify(engagementOptionsTimeFilter),
    nodeFilterOptionsTimeFilter: JSON.stringify(nodeFilterOptionsTimeFilter),
    nodeFilterType: JSON.stringify(nodeFilterType),
    familyFilterType: JSON.stringify(familyFilterType),
    professionalFilterType: JSON.stringify(professionalFilterType),
    organizationalFilterType: JSON.stringify(organizationalFilterType),
    charitableFilterType: JSON.stringify(charitableFilterType),
    educationFilterType: JSON.stringify(educationFilterType),
    engagementFilterType: JSON.stringify(engagementFilterType),
    nodeFilterOut: JSON.stringify(nodeFilterOut),
    edgesFilterOut: JSON.stringify(edgesFilterOut),
    showVisibleConnection: JSON.stringify(showVisibleConnections),
  });
});
export const selectProspectStatus = createSelector(
  [filter],
  (state) => state.prospectStatus,
);
export const selectShowVisibleConnections = createSelector(
  [filter],
  (state) => state.showVisibleConnections,
);

export const selectTimeFilters = createSelector([filter], (state) => {
  const {
    familyOptionsTimeFilter,
    professionalOptionsTimeFilter,
    organizationalOptionsTimeFilter,
    charitableOptionsTimeFilter,
    educationOptionsTimeFilter,
    engagementOptionsTimeFilter,
    nodeFilterOptionsTimeFilter,
  } = state;
  return {
    familyOptionsTimeFilter,
    professionalOptionsTimeFilter,
    organizationalOptionsTimeFilter,
    charitableOptionsTimeFilter,
    educationOptionsTimeFilter,
    engagementOptionsTimeFilter,
    nodeFilterOptionsTimeFilter,
  };
});

export const selectEdgeFilters = createSelector([filter], (state) => {
  const {
    professionalOptions,
    familyOptions,
    organizationalOptions,
    educationOptions,
    charitableOptions,
    engagementOptions,
    professionalOptionsEnabled,
    familyOptionsEnabled,
    organizationalOptionsEnabled,
    educationOptionsEnabled,
    charitableOptionsEnabled,
    engagementOptionsEnabled,
    edgesFilterOut,
  } = state;

  let arr = [];
  let filterOutArr = [];
  if (professionalOptionsEnabled) {
    arr = [...arr, ...professionalOptions];
    filterOutArr = [...filterOutArr, ...edgesFilterOut.professionalOptions];
  }
  if (familyOptionsEnabled) {
    arr = [...arr, ...familyOptions];
    filterOutArr = [...filterOutArr, ...edgesFilterOut.familyOptions];
  }
  if (organizationalOptionsEnabled) {
    arr = [...arr, ...organizationalOptions];
    filterOutArr = [...filterOutArr, ...edgesFilterOut.organizationalOptions];
  }
  if (educationOptionsEnabled) {
    arr = [...arr, ...educationOptions];
    filterOutArr = [...filterOutArr, ...edgesFilterOut.educationOptions];
  }
  if (charitableOptionsEnabled) {
    arr = [...arr, ...charitableOptions];
    filterOutArr = [...filterOutArr, ...edgesFilterOut.charitableOptions];
  }
  if (engagementOptionsEnabled) {
    arr = [...arr, ...engagementOptions];
    filterOutArr = [...filterOutArr, ...edgesFilterOut.engagementOptions];
  }
  return { edgeFilters: arr, removeEdges: filterOutArr };
});

export const selectNodeFiltersOptions = createSelector([filter], (state) => {
  if (state.nodeFilterOptionsEnabled) {
    return [...state.nodeFilterOptions];
  }
  return [];
});

export const selectNodeFilterType = createSelector(
  [filter],
  (state) => state.nodeFilterType,
);
export const selectNodeFilterOut = createSelector([filter], (state) => {
  if (state.nodeFilterOptionsEnabled) {
    return [...state.nodeFilterOut];
  }
  return [];
});

export const selectFamilyOptionsEnabled = createSelector(
  [filter],
  (state) => state.familyOptionsEnabled,
);
export const selectProfressionalOptionsEnabled = createSelector(
  [filter],
  (state) => state.professionalOptionsEnabled,
);
export const selectOrganizationalOptionsEnabled = createSelector(
  [filter],
  (state) => state.organizationalOptionsEnabled,
);
export const selectCharitableOptionsEnabled = createSelector(
  [filter],
  (state) => state.charitableOptionsEnabled,
);
export const selectEducationOptionsEnabled = createSelector(
  [filter],
  (state) => state.educationOptionsEnabled,
);
export const selectEngagementOptionsEnabled = createSelector(
  [filter],
  (state) => state.engagementOptionsEnabled,
);
export const selectNodeOptionsEnabled = createSelector(
  [filter],
  (state) => state.nodeFilterOptionsEnabled,
);
export const selectShortestPathNodeId = createSelector(
  [filter],
  (state) => state.shortestPathNodeId,
);

export const selectEdgeFilterType = createSelector([filter], (state) => {
  const {
    familyFilterType,
    professionalFilterType,
    organizationalFilterType,
    charitableFilterType,
    educationFilterType,
    engagementFilterType,
  } = state;
  if (
    familyFilterType === SELECTED_STATE ||
    professionalFilterType === SELECTED_STATE ||
    organizationalFilterType === SELECTED_STATE ||
    charitableFilterType === SELECTED_STATE ||
    educationFilterType === SELECTED_STATE ||
    engagementFilterType === SELECTED_STATE
  ) {
    return SELECTED_STATE;
  } else if (
    familyFilterType === HIGHLIGHTED_STATE ||
    professionalFilterType === HIGHLIGHTED_STATE ||
    organizationalFilterType === HIGHLIGHTED_STATE ||
    charitableFilterType === HIGHLIGHTED_STATE ||
    educationFilterType === HIGHLIGHTED_STATE ||
    engagementFilterType === HIGHLIGHTED_STATE
  ) {
    return HIGHLIGHTED_STATE;
  } else {
    return UNSELECTED_STATE;
  }
});

export const selectRegionFilter = createSelector(
  [filter],
  (state) => state.regions,
);
export const selectDegreesOfSeparation = createSelector(
  [filter],
  (state) => state.degreesOfSeparation,
);
export const selectNodeSizingType = createSelector(
  [filter],
  (state) => state.nodeSizingType,
);
export const selectTotalConnections = createSelector(
  [filter],
  (state) => state.totalConnections,
);
export const selectTotalLifetimeGivingMax = createSelector(
  [filter],
  (state) => state.totalLifetimeGivingMax,
);
export const selectTotalLifetimeGivingMin = createSelector(
  [filter],
  (state) => state.totalLifetimeGivingMin,
);
export const selectAffiliationFilter = createSelector(
  [filter],
  (state) => state.affiliation,
);
export const selectConstituencyFilter = createSelector(
  [filter],
  (state) => state.constituency,
);

export const selectShowDeceasedConnections = createSelector(
  [filter],
  (state) => state.showDeceasedConnections,
);
export const selectViewFilterUpdates = createSelector([filter], (state) => {
  const {
    familyFilterType,
    professionalFilterType,
    organizationalFilterType,
    charitableFilterType,
    educationFilterType,
    engagementFilterType,
    nodeFilterType,
  } = state;
  return {
    viewFilterUpdates: state.viewFilterUpdates,
    familyFilterType,
    professionalFilterType,
    organizationalFilterType,
    charitableFilterType,
    educationFilterType,
    engagementFilterType,
    nodeFilterType,
  };
});

export const selectTotalLifetimeGiving = createSelector(
  [selectTotalLifetimeGivingMin, selectTotalLifetimeGivingMax],
  (min, max) => {
    return { min, max };
  },
);
export const selectProspectStatusForFilter = createSelector(
  [filter],
  (state) => {
    return Object.values(state.prospectStatus)
      .filter((x) => x.isSelected)
      .map((x) => {
        return x.value;
      });
  },
);

export const selectNodeFilters = createSelector(
  [
    selectRegionFilter,
    selectConstituencyFilter,
    selectAffiliationFilter,
    selectTotalLifetimeGiving,
    selectProspectStatusForFilter,
    selectTotalConnections,
    selectDegreesOfSeparation,
    selectNodeSizingType,
    selectShowDeceasedConnections,
  ],
  (
    region,
    constituency,
    affiliation,
    lifetimeGiving,
    prospectStatus,
    totalConnections,
    degreeOfSeparation,
    nodeSizingType,
    showDeceasedConnections,
  ) => {
    return {
      region,
      constituency,
      affiliation,
      lifetimeGiving,
      prospectStatus,
      totalConnections,
      degreeOfSeparation,
      nodeSizingType,
      showDeceasedConnections,
    };
  },
);

export const selectReset = createSelector([filter], (state) => {
  return state.reset;
});

export const selectHiddenNodes = createSelector([filter], (state) => {
  return state.hiddenNodes;
});

export const selectLoadingContext = createSelector(
  [filter],
  (state) => state.loadingViewForContext,
);
