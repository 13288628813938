import { applyMiddleware, combineReducers, legacy_createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga'
import filtersReducer from './filters/reducer';
import graphReducer from './graph/reducer';
import rootSaga from './rootSaga';
import coreReducer from './core/reducer';
import loginReducer from './login/reducer';

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const rootReducer = combineReducers({
        filters: filtersReducer,
        graph: graphReducer,
        core: coreReducer,
        login: loginReducer
    });
    
    const store = legacy_createStore(
        rootReducer,
        composeWithDevTools(
            applyMiddleware(sagaMiddleware)
        )
    );
    
    sagaMiddleware.run(rootSaga);
    return store;
}

export default configureStore;