export const currencyFormatter = (number) => {
  const fixed = Math.round(number);
  return (fixed).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
}

export const currencyToNumberFormatter = (curr) => {
  var currency = (curr).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
  });
  var number = Number(currency.replace(/[^0-9.-]+/g,""));
  return number;
}