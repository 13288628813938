import React from 'react';
import EducationRelationshipCard from './cards/education';
import Person from './cards/person';

const Education = ({topNode, bottomNode, topRelation, bottomRelation }) => {
    if(!bottomRelation)  bottomRelation = topRelation;
    return (
        <>
            <Person properties={topNode} headerText={topRelation} />
            <EducationRelationshipCard properties={bottomNode} headerText={bottomRelation} />
        </>
    );
}

export default Education;
