import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNodeSizingType } from 'store/filters/actions';
import { selectNodeSizingType } from 'store/filters/selectors';
import DropDown from '../../drop-down';
import styles from './nodeSizing.module.css';

const NodeSizing = () => {
  const [open, setOpen] = useState(false);
  const sizing = useSelector(selectNodeSizingType);
  const dispatch = useDispatch();
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleFirst = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(setNodeSizingType(0));
  };
  const handleSecond = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(setNodeSizingType(1));
  };
  const handleThird = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(setNodeSizingType(2));
  };

  return (
    <div className={!open ? styles.wrapper : styles.open_wrapper}>
      <DropDown filterText="Size Nodes by" onClick={handleToggle} open={open} />
      {open && (
        <div className={styles.open_region}>
          <div
            className={
              sizing === 0 ? styles.first_degree_selected : styles.first_degree
            }
            onClick={handleFirst}
          >
            Most Connected
          </div>
          <div
            className={sizing === 1 ? styles.degree_selected : styles.degree}
            onClick={handleSecond}
          >
            Highest Capacity
          </div>
          <div
            className={
              sizing >= 2 ? styles.last_degree_selected : styles.last_degree
            }
            onClick={handleThird}
          >
            Largest Donors
          </div>
        </div>
      )}
    </div>
  );
};

export default NodeSizing;
