import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatVertexSearchResponse } from 'utils/formatters/search-formatter';
import { BsSearch, BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';
import styles from './searchInput.module.css';
import SearchItem from './search-item';
import { useDispatch, useSelector } from 'react-redux';
import { setPeerView, setSearch } from 'store/graph/actions';
import { selectConnection } from 'store/core/selectors';
import debounceFunction from './debounce';
import SearchItemRegion from './search-item-region';
import { setRegionFilter } from 'store/filters/actions';
import useComponentVisible from 'utils/hooks';
import { resetCompleteGraph } from 'store/core/actions';
import { IsGraphEmpty } from 'store/graph/selectors';
import ReactTooltip from 'react-tooltip';

const Nodes = 'Nodes';
const Regions = 'Region';
const Peers = 'List of IDs';

const SearchInput = () => {
  const clearItems = () => {
    setItems([]);
  };
  const dispatch = useDispatch();
  const connection = useSelector(selectConnection);
  const isEmpty = useSelector(IsGraphEmpty);
  const [searchType, setSearchType] = useState(Nodes);
  const [search, setLocalSearch] = useState('');
  const [items, setItems] = useState([]);
  const [val, setVal] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const { ref, isVisible, setIsVisible } = useComponentVisible(
    false,
    clearItems,
  );

  const getDropDown = (val) => {
    setVal(val);
  };

  useEffect(() => {
    setIsLoading(true);
    if (connection != null) {
      if (val?.length > 0) {
        const type = searchType === Nodes ? 'vertex' : 'region';
        connection.invoke('filterSearch', val, type).then((response) => {
          setItems(formatVertexSearchResponse(response));
          setIsLoading(false);
        });
      } else if (val?.length === 0) {
        setItems([]);
        setIsVisible(false);
      }
    }
  }, [connection, searchType, val]);

  const debounceDropDown = useCallback(
    debounceFunction((nextValue) => getDropDown(nextValue), 500),
    [],
  );

  const onChange = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    const value = e.target.value;
    setLocalSearch(value);
    if (value === '') {
      setItems([]);
      setIsVisible(false);
    } else {
      setIsVisible(true);
      debounceDropDown(value);
    }
  };

  const handleOnSelect = (context) => {
    setItems([]);
    setLocalSearch('');
    setVal(undefined);
    dispatch(setSearch(context));
    setIsVisible(false);
    if (searchType === 'Region') {
      dispatch(setRegionFilter(context.metroRegion));
    }
  };

  const style =
    search.length > 0 && isVisible
      ? {
          borderRadius: '8px 8px 0px 0px',
        }
      : {};

  const setValue = (value) => {
    setItems([]);
    setSearchType(value);
  };

  const clearAll = () => {
    dispatch(resetCompleteGraph());
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.search_wrapper}>
        <p className={styles.search_header}>Search By:</p>
        <SearchByHeader value={searchType} setValue={setValue} />
        {!isEmpty && (
          <>
            <div
              className={styles.clearAll}
              onClick={clearAll}
              data-for="clear-all-filters-nodes"
              data-tip
            >
              Reset
            </div>
            <ReactTooltip
              id="clear-all-filters-nodes"
              place="right"
              effect="solid"
            >
              <div style={{ maxWidth: '110px' }}>
                This action will reset the whole graph
              </div>
            </ReactTooltip>
          </>
        )}
      </div>
      <div className={styles.search_card_wrapper}>
        {searchType !== Peers && (
          <div className={styles.input_wrapper}>
            <input
              className={`${styles.search_input}`}
              style={style}
              type="text"
              value={search}
              onChange={onChange}
            />
            <BsSearch className={styles.icon} />
          </div>
        )}
        {searchType === Peers && <SearchByPeers />}
        <div ref={ref}>
          {isVisible && ((items && items.length > 0) || search.length > 0) && (
            <div className={styles.items_wrapper}>
              {searchType === Nodes &&
                items.length > 0 &&
                items.map((item) => (
                  <SearchItem
                    key={item.id}
                    onSelect={handleOnSelect}
                    item={item}
                  />
                ))}
              {searchType === Regions &&
                items.length > 0 &&
                items.map((item) => (
                  <SearchItemRegion
                    key={item.id}
                    onSelect={handleOnSelect}
                    item={item}
                  />
                ))}
              {items.length === 0 && search.length > 0 && !isLoading && (
                <div className={styles.items_error}>No matching items</div>
              )}
              {items.length === 0 && search.length > 0 && isLoading && (
                <div className={styles.items_error}>Loading</div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SearchInput.propTypes = {
  setSearch: PropTypes.func,
  value: PropTypes.string,
};

export default SearchInput;

export const SearchByHeader = ({ value, setValue }) => {
  const { ref, isVisible, setIsVisible } = useComponentVisible(false);
  const handleOpen = (e) => {
    setIsVisible(true);
  };

  const handleSelect = (newValue) => {
    setValue(newValue);
    setIsVisible(false);
  };

  //  todo: remove bad () =>
  return (
    <div className={styles.strengthOfRelationship}>
      <div className={styles.inputwrapperFull} onClick={handleOpen}>
        <div className={styles.searchTypeHeaderWrapper}>
          <div className={styles.search_type}>{value}</div>
          {!isVisible && <BsCaretDownFill className={styles.arrow_down} />}
          {isVisible && <BsCaretUpFill className={styles.arrow_down} />}
        </div>
      </div>
      <div ref={ref}>
        {isVisible && (
          <div className={styles.dropDownCard} ref={ref}>
            <div className={styles.options} onClick={() => handleSelect(Nodes)}>
              Nodes
            </div>
            <div
              className={styles.options}
              onClick={() => handleSelect(Regions)}
            >
              Regions
            </div>
            <div className={styles.options} onClick={() => handleSelect(Peers)}>
              List of IDs
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const SearchByPeers = () => {
  const [error, setError] = useState('');
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    setError('');
    const values = value.split(/(?:,| |;|:|\t|\n|\r)+/);
    if (values.length < 1 || (values.length < 2 && values[0] === '')) {
      setError(
        'Failed to parse values. Please make sure they are properly separated',
      );
      return;
    }
    for (let i = 0; i < values.length; i++) {
      const number = Number(values[i]);
      if (isNaN(number)) {
        setError('Please put only the id numbers, ex: 123');
        return;
      }
      values[i] = number;
    }
    dispatch(setPeerView(values));
    setValue('');
  };
  return (
    <div className={styles.textarea_wrapper}>
      <textarea
        className={`${styles.search_input} ${styles.textarea}`}
        type="text"
        value={value}
        onChange={onChange}
      />
      <div className={styles.button} onClick={handleSubmit}>
        Submit
      </div>
      {error && <div className={styles.textarea_error}>{error}</div>}
    </div>
  );
};
