import debounceFunction from 'common/search-input/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAffiliationFilter } from 'store/filters/actions';
import { selectAffiliationFilter } from 'store/filters/selectors';
import DropDown from '../../drop-down';
import styles from './affiliation.module.css';

const AfilliationFilter = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const aff = useSelector(selectAffiliationFilter);
  const [search, setSearch] = useState(aff);
  const handleToggle = () => {
    setOpen(!open);        
  };

  const dispatchValue = (val) => {
    dispatch(setAffiliationFilter(val.toLowerCase()));
  };

  const debounceDropDown = useCallback(
    debounceFunction((nextValue) => dispatchValue(nextValue), 500),
    []
  );

  const onChange = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    const value = e.target.value;
    setSearch(value);
    debounceDropDown(value);
  };

  useEffect(() => {
    setSearch(aff);
  }, [aff])

  return (
    <div className={!open ? 'filter_by_wrapper' : 'filter_by_open_wrapper'}>
      <DropDown filterText="Affliation" onClick={handleToggle} open={open} />
      {open && 
        <div className={styles.open_region}>
          <div className={styles.input_region}>
            <div className={styles.input_wrapper}>
              {/* <label className={styles.option}>Affiliation</label> */}
              <input type='text' className={styles.input} onChange={onChange} value={search} placeholder='Enter Affiliation Filter' />
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default AfilliationFilter;