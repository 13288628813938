import React from "react";
import PropTypes from 'prop-types';
import { BsChevronRight } from 'react-icons/bs';
import CardHeader from "../../card-header";
import Img from '../../img-container';
import styles from '../styles/person-relationship-card.module.css';
import { EducationIcon } from "common/icon/icon";

const EducationRelationshipCard = ({ properties, headerText }) => {
    const { name, institution, college, imgSrc, department, major } = properties;
    return (
        <div className={styles.card}>
            <CardHeader value={headerText} icon={<EducationIcon className={styles.education_icon} />} />
            <div className={styles.content}>
                <div className={styles.content_header}>
                    <div className={styles.left_section}>
                        <div className={styles.name}>
                            <h2 className={styles.name_desc}>{name}</h2>
                            <BsChevronRight className={styles.name_icon} />
                        </div>
                        <div className={styles.person_details_wrapper}>
                            <div className={styles.person_details_title}>Institution:</div>
                            <div className={styles.person_details_value}>{institution}</div>
                        </div>
                        <div className={styles.person_details_wrapper}>
                            <div className={styles.person_details_title}>College:</div>
                            <div className={styles.person_details_value}>{college}</div>
                        </div>
                        <div className={styles.person_details_wrapper}>
                            <div className={styles.person_details_title}>Department:</div>
                            <div className={styles.person_details_value}>{department}</div>
                        </div>
                        <div className={styles.person_details_wrapper}>
                            <div className={styles.person_details_title}>Major:</div>
                            <div className={styles.person_details_value}>{major}</div>
                        </div>
                    </div>
                    <div className={styles.right_section}>
                        <Img src={imgSrc} size="64px" />
                    </div>
                </div>
            </div>
        </div>
    );
}

EducationRelationshipCard.propTypes = {
    properties: PropTypes.object,
}

export default EducationRelationshipCard;