import React from "react";
import PropTypes from "prop-types";
import SplitInput from "../../input/split-input";
import Input from "../../input";
import styles from "./styles/snapshot.module.css";

const Snapshot = ({ snapshot, titleColor = "#38B2D9" }) => {
  const { affiliationScores, universityGiving, total } = snapshot;
  return (
    <div className={styles.wrapper}>
      <div className={styles.title} style={{ borderColor: titleColor }}>
        Snapshot
      </div>
      <div className={styles.card}>
        <Input value={total} label="Total lifetime giving" />
        <div className={styles.university_section}>
          <div className={styles.label}>University level giving</div>
          {universityGiving?.length > 0 &&
            universityGiving.map((e) => {
              return <SplitInput title={e.name} amount={e.amount} />;
            })}
        </div>
      </div>
      {affiliationScores?.length > 0 && (
        <div className={styles.card}>
          {affiliationScores.map((e) => {
            return <SplitInput title={e.name} amount={e.amount} />;
          })}
        </div>
      )}
    </div>
  );
};

Snapshot.propTypes = {
  totalGiving: PropTypes.string,
  universityGivings: PropTypes.array,
  prospect_status: PropTypes.object,
};

export default Snapshot;
