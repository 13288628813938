import React from 'react';
import PropTypes from 'prop-types';
import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';
import styles from './dropDown.module.css';

const DropDown = ({ filterText, onClick, open, clear, clearInsteadOfIcon }) => {
  let icon = <BsCaretDownFill className={styles.arrow_down} />;
  if (open) {
    icon = <BsCaretUpFill className={styles.arrow_down} />;
  }
  return (
    <div className={styles.drop_down} onClick={onClick}>
      <div className={styles.name}>{filterText}</div>
      {!clearInsteadOfIcon && icon}
      {clearInsteadOfIcon && (
        <div className={styles.header_type} onClick={clear}>
          Clear
        </div>
      )}
    </div>
  );
};

DropDown.propTypes = {
  filterText: PropTypes.string,
};

export default DropDown;
