import React from "react";
import BaseOptionsCard from "./base-options-card";
import {
  FILTER_OUT_SELECTED_STATE,
  HIGHLIGHTED_STATE,
  SELECTED_STATE,
  UNSELECTED_STATE,
} from "store/filters/constants";

const OptionsCardWrapper = ({
  timeFilterWrapper,
  options,
  setOptions,
  setGroupOptions,
  dispatchFilters,
  close,
  icon,
  headerText,
  clear,
  color,
  customGrouping,
  customGroupingMapping,
  showAdvanced = undefined,
  setShowAdvanced = undefined,
}) => {
  const updateOptions = (key, isSelected) => {
    const newOptions = { ...options };
    newOptions[key] = { isSelected };
    setOptions(newOptions);
  };
  const groupUpdateOptions = (keys, value, isSelected) => {
    const newOptions = { ...options };
    keys.forEach((x) => {
      newOptions[x] = { isSelected };
    });
    const newGroupOptions = { ...customGrouping };
    newGroupOptions[value] = { isSelected };
    setOptions(newOptions);
    setGroupOptions(newGroupOptions);
  };
  const setFilters = () => {
    const keys = Object.keys(options);
    let filterOut = [];
    const values = keys.filter((x) => {
      const obj = options[x];
      if (obj.isSelected === FILTER_OUT_SELECTED_STATE) {
        filterOut.push(x);
      }
      return (
        obj.isSelected !== UNSELECTED_STATE &&
        obj.isSelected !== FILTER_OUT_SELECTED_STATE
      );
    });
    const type = getType(values, options);
    dispatchFilters({
      type,
      values,
      filterOut,
      timeFilter: timeFilterWrapper?.timeFilter,
    });
    close();
  };
  const clearFilters = (e, keepOpen = false) => {
    dispatchFilters({
      type: UNSELECTED_STATE,
      values: [],
      filterOut: [],
      timeFilter: { start: null, end: null },
    });
    clear();
    if (!keepOpen) {
      close();
    }
  };

  return (
    <BaseOptionsCard
      options={options}
      icon={icon}
      headerText={headerText}
      updateOptions={updateOptions}
      setFilters={setFilters}
      clear={clearFilters}
      color={color}
      timeFilter={timeFilterWrapper}
      customGrouping={customGrouping}
      customGroupingMapping={customGroupingMapping}
      groupUpdateOptions={groupUpdateOptions}
      setBaseOptions={setOptions}
      showAdvanced={showAdvanced}
      setShowAdvanced={setShowAdvanced}
    />
  );
};

OptionsCardWrapper.propType = {};

export default OptionsCardWrapper;

const getType = (values, options) => {
  let type = UNSELECTED_STATE;
  values.some((element, index) => {
    const value = options[element].isSelected;
    if (value === SELECTED_STATE) {
      type = SELECTED_STATE;
      return true;
    } else if (value === HIGHLIGHTED_STATE) {
      type = HIGHLIGHTED_STATE;
    }
    return false;
  });
  return type;
};
