import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { clearHiddenNodes, removeHiddenNode } from 'store/filters/actions';
import DropDown from '../../drop-down';
import styles from './hiddenNodes.module.css';

const HiddenNodesFilter = ({ nodes, hiddenNodeIds }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleClear = (nodeId) => {
    dispatch(removeHiddenNode(nodeId));
  };
  const clearAll = (nodeId) => {
    dispatch(clearHiddenNodes(nodeId));
  };

  return (
    <div className={styles.wrapper}>
      <DropDown
        filterText="Hidden Nodes"
        onClick={handleToggle}
        open={open}
        clearInsteadOfIcon
        clear={clearAll}
      />
      {hiddenNodeIds.length > 0 && (
        <div className={styles.regions_wrapper}>
          {hiddenNodeIds.map((nodeId) => (
            <div className={styles.regions_item} key={nodeId}>
              {nodes[nodeId]}
              <AiOutlineClose
                className={styles.clear_icon}
                onClick={() => handleClear(nodeId)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HiddenNodesFilter;
