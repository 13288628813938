import { SET_ERROR, SET_IS_LOADING, SET_LOGIN } from './constants';

export const setLogin = (username, pass) => {
  return {
    type: SET_LOGIN,
    username,
    pass,
  };
};

export const setError = (error) => {
  return {
    type: SET_ERROR,
    error,
  };
};

export const setIsLoading = (loading) => {
  return {
    type: SET_IS_LOADING,
    loading,
  };
};
