import { HIGHLIGHTED_STATE, SELECTED_STATE } from 'store/filters/constants';
import {
  canAddNode,
  GetNodeSelectionStyles,
  getSize,
  IsObjectEmpty,
} from 'utils/graph-helper';
import {
  RELATIONSHIP_TYPE_CHARITABLE,
  RELATIONSHIP_TYPE_EDUCATION,
  RELATIONSHIP_TYPE_ENGAGEMENT,
  RELATIONSHIP_TYPE_FAMILY,
  RELATIONSHIP_TYPE_ORGANIZATIONAL,
  RELATIONSHIP_TYPE_PERSONAL,
} from './constants';

export const selectItemsHelper = (
  nodes,
  links,
  contextIds,
  filtersForEdges,
  edgeFilterType,
  nodeOptions,
  nodeFilterType,
  regions,
  nodeFilters,
  nodeTypeFilterOut,
  shortestPathNodeid = '',
  timeFilters,
  contexts,
  hiddenNodes,
) => {
  let items = {};
  let nodesAlreadyStyled = {};
  // add context nodes
  const ids = Object.keys(contextIds);
  let p2pIds = [];
  ids.forEach((x) => {
    if (x.includes('p2p')) {
      p2pIds.push(contexts[x]);
    } else {
      const { data, ...nodeToAdd } = nodes[x]?.node;
      items[x] = nodeToAdd;
      nodesAlreadyStyled[x] = x;
    }
  });

  const { edgeFilters, removeEdges } = filtersForEdges;
  const nodeFiltersEnabled =
    nodeOptions.length > 0 || nodeTypeFilterOut.length > 0;
  const isRegionsFilterEmpty = IsObjectEmpty(regions);
  const constitencies = Object.keys(nodeFilters.constituency);
  const nodeSizingType = nodeFilters.nodeSizingType;
  const isConstituenciesEmpty = constitencies.length < 1;

  // add nodes from links
  for (const linkId in links) {
    const graphLink = links[linkId]?.link;
    const [nodeOneId, nodeTwoId] = linkId.split('/p/');
    if (
      removeEdges.length > 0 &&
      isEdgeIncluded(graphLink, removeEdges, timeFilters)
    ) {
      // don't add link which means don't add nodes
    } else if (edgeFilters.length < 1) {
      // if no edge filters, show normal node graph
      const nodeOne = nodes[nodeOneId]?.node;
      const nodeTwo = nodes[nodeTwoId]?.node;
      let added1 = false;
      let added2 = false;
      // check if node already added
      if (items[nodeOneId] !== undefined) {
        added1 = true;
      } else if (nodeFiltersEnabled) {
        if (nodeFilterType === HIGHLIGHTED_STATE) {
          if (
            canAddNode(
              nodeOne,
              isRegionsFilterEmpty,
              isConstituenciesEmpty,
              constitencies,
              nodeFilters,
              hiddenNodes,
            ) &&
            !nodeTypeFilterOut.includes(nodeOne.data.nodeType)
          ) {
            let newNode = nodeOne;
            if (nodeOptions.includes(nodeOne.data.nodeType)) {
              const nodeOneSelectionStyles = GetNodeSelectionStyles(nodeOne);
              newNode = { ...nodeOne, ...nodeOneSelectionStyles };
            }

            const { data, ...nodeToAdd } = newNode;
            const size = getSize(nodeSizingType, data);
            items[nodeOneId] = { ...nodeToAdd, size };
            added1 = true;
          }
        } else if (nodeFilterType === SELECTED_STATE) {
          if (
            nodeOptions.includes(nodeOne.data.nodeType) &&
            canAddNode(
              nodeOne,
              isRegionsFilterEmpty,
              isConstituenciesEmpty,
              constitencies,
              nodeFilters,
              hiddenNodes,
            )
          ) {
            const nodeOneSelectionStyles = GetNodeSelectionStyles(nodeOne);
            const { data, ...nodeToAdd } = {
              ...nodeOne,
              ...nodeOneSelectionStyles,
            };
            const size = getSize(nodeSizingType, data);
            items[nodeOneId] = { ...nodeToAdd, size };
            added1 = true;
          } else {
          }
        } else {
          if (
            canAddNode(
              nodeOne,
              isRegionsFilterEmpty,
              isConstituenciesEmpty,
              constitencies,
              nodeFilters,
              hiddenNodes,
            ) &&
            !nodeTypeFilterOut.includes(nodeOne.data.nodeType)
          ) {
            const { data, ...nodeToAdd } = nodeOne;
            const size = getSize(nodeSizingType, data);
            items[nodeOneId] = { ...nodeToAdd, size };
            added1 = true;
          }
        }
      } else if (
        canAddNode(
          nodeOne,
          isRegionsFilterEmpty,
          isConstituenciesEmpty,
          constitencies,
          nodeFilters,
          hiddenNodes,
        )
      ) {
        const { data, ...nodeToAdd } = nodeOne;
        const size = getSize(nodeSizingType, data);
        items[nodeOneId] = { ...nodeToAdd, size };
        added1 = true;
      }

      if (items[nodeTwoId] !== undefined) {
        added2 = true;
      } else if (nodeFiltersEnabled) {
        if (nodeFilterType === HIGHLIGHTED_STATE) {
          if (
            canAddNode(
              nodeTwo,
              isRegionsFilterEmpty,
              isConstituenciesEmpty,
              constitencies,
              nodeFilters,
              hiddenNodes,
            ) &&
            !nodeTypeFilterOut.includes(nodeTwo.data.nodeType)
          ) {
            let newNode = {};
            if (nodeOptions.includes(nodeTwo.data.nodeType)) {
              const nodeTwoSelectionStyles = GetNodeSelectionStyles(nodeTwo);
              newNode = { ...nodeTwo, ...nodeTwoSelectionStyles };
            } else {
              newNode = nodeTwo;
            }
            const { data, ...nodeToAdd } = newNode;
            const size = getSize(nodeSizingType, data);
            items[nodeTwoId] = { ...nodeToAdd, size };
            added2 = true;
          }
        } else if (nodeFilterType === SELECTED_STATE) {
          if (
            nodeOptions.includes(nodeTwo.data.nodeType) &&
            canAddNode(
              nodeTwo,
              isRegionsFilterEmpty,
              isConstituenciesEmpty,
              constitencies,
              nodeFilters,
              hiddenNodes,
            )
          ) {
            const nodeTwoSelectionStyles = GetNodeSelectionStyles(nodeTwo);
            const { data, ...nodeToAdd } = {
              ...nodeTwo,
              ...nodeTwoSelectionStyles,
            };
            const size = getSize(nodeSizingType, data);
            items[nodeTwoId] = { ...nodeToAdd, size };
            added2 = true;
          } else {
          }
        } else {
          if (
            canAddNode(
              nodeTwo,
              isRegionsFilterEmpty,
              isConstituenciesEmpty,
              constitencies,
              nodeFilters,
              hiddenNodes,
            ) &&
            !nodeTypeFilterOut.includes(nodeTwo.data.nodeType)
          ) {
            const { data, ...nodeToAdd } = nodeTwo;
            const size = getSize(nodeSizingType, data);
            items[nodeTwoId] = { ...nodeToAdd, size };
            added2 = true;
          }
        }
      } else if (
        canAddNode(
          nodeTwo,
          isRegionsFilterEmpty,
          isConstituenciesEmpty,
          constitencies,
          nodeFilters,
          hiddenNodes,
        )
      ) {
        const { data, ...nodeToAdd } = nodeTwo;
        const size = getSize(nodeSizingType, data);
        items[nodeTwoId] = { ...nodeToAdd, size };
        added2 = true;
      }

      // add link if both nodes added
      if (added1 && added2) {
        const { data, ...newLinkToAdd } = graphLink;
        items[linkId] = newLinkToAdd;
      }
    } else {
      if (edgeFilterType === HIGHLIGHTED_STATE) {
        // filterType is selected state
        const nodeOne = nodes[nodeOneId]?.node;
        const nodeTwo = nodes[nodeTwoId]?.node;
        const isIncluded = isEdgeIncluded(
          graphLink,
          edgeFilters,
          timeFilters,
          true,
        );
        if (isIncluded) {
          let added1 = false;
          let added2 = false;

          if (
            items[nodeOneId] !== undefined &&
            nodesAlreadyStyled[nodeOneId] !== undefined
          ) {
            added1 = true;
          } else if (nodeFiltersEnabled) {
            if (nodeFilterType === HIGHLIGHTED_STATE) {
              if (
                canAddNode(
                  nodeOne,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                ) &&
                !nodeTypeFilterOut.includes(nodeOne.data.nodeType)
              ) {
                const nodeOneSelectionStyles = GetNodeSelectionStyles(nodeOne);
                const { data, ...nodeToAdd } = {
                  ...nodeOne,
                  ...nodeOneSelectionStyles,
                };
                const size = getSize(nodeSizingType, data);
                items[nodeOneId] = { ...nodeToAdd, size };
                nodesAlreadyStyled[nodeOneId] = nodeOneId;
                added1 = true;
              }
            } else if (nodeFilterType === SELECTED_STATE) {
              if (
                nodeOptions.includes(nodeOne.data.nodeType) &&
                canAddNode(
                  nodeOne,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                )
              ) {
                const nodeOneSelectionStyles = GetNodeSelectionStyles(nodeOne);
                const { data, ...nodeToAdd } = {
                  ...nodeOne,
                  ...nodeOneSelectionStyles,
                };
                const size = getSize(nodeSizingType, data);
                items[nodeOneId] = { ...nodeToAdd, size };
                nodesAlreadyStyled[nodeOneId] = nodeOneId;
                added1 = true;
              } else {
              }
            } else {
              if (
                canAddNode(
                  nodeOne,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                ) &&
                !nodeTypeFilterOut.includes(nodeOne.data.nodeType)
              ) {
                const nodeOneSelectionStyles = GetNodeSelectionStyles(nodeOne);
                const { data, ...nodeToAdd } = {
                  ...nodeOne,
                  ...nodeOneSelectionStyles,
                };
                const size = getSize(nodeSizingType, data);
                items[nodeOneId] = { ...nodeToAdd, size };
                nodesAlreadyStyled[nodeOneId] = nodeOneId;
                added1 = true;
              }
            }
          } else if (
            canAddNode(
              nodeOne,
              isRegionsFilterEmpty,
              isConstituenciesEmpty,
              constitencies,
              nodeFilters,
              hiddenNodes,
            )
          ) {
            const nodeOneSelectionStyles = GetNodeSelectionStyles(nodeOne);
            const { data, ...nodeToAdd } = {
              ...nodeOne,
              ...nodeOneSelectionStyles,
            };
            const size = getSize(nodeSizingType, data);
            items[nodeOneId] = { ...nodeToAdd, size };
            nodesAlreadyStyled[nodeOneId] = nodeOneId;
            added1 = true;
          }

          if (
            items[nodeTwoId] !== undefined &&
            nodesAlreadyStyled[nodeTwoId] !== undefined
          ) {
            added2 = true;
          } else if (nodeFiltersEnabled) {
            if (nodeFilterType === HIGHLIGHTED_STATE) {
              if (
                canAddNode(
                  nodeTwo,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                ) &&
                !nodeTypeFilterOut.includes(nodeTwo.data.nodeType)
              ) {
                const nodeTwoSelectionStyles = GetNodeSelectionStyles(nodeTwo);
                const { data, ...nodeToAdd } = {
                  ...nodeTwo,
                  ...nodeTwoSelectionStyles,
                };
                const size = getSize(nodeSizingType, data);
                items[nodeTwoId] = { ...nodeToAdd, size };
                added2 = true;
                nodesAlreadyStyled[nodeTwoId] = nodeTwoId;
              }
            } else if (nodeFilterType === SELECTED_STATE) {
              if (
                nodeOptions.includes(nodeTwo.data.nodeType) &&
                canAddNode(
                  nodeTwo,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                )
              ) {
                const nodeTwoSelectionStyles = GetNodeSelectionStyles(nodeTwo);
                const { data, ...nodeToAdd } = {
                  ...nodeTwo,
                  ...nodeTwoSelectionStyles,
                };
                const size = getSize(nodeSizingType, data);
                items[nodeTwoId] = { ...nodeToAdd, size };
                nodesAlreadyStyled[nodeTwoId] = nodeTwoId;
                added2 = true;
              } else {
              }
            } else {
              if (
                canAddNode(
                  nodeTwo,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                ) &&
                !nodeTypeFilterOut.includes(nodeTwo.data.nodeType)
              ) {
                const nodeTwoSelectionStyles = GetNodeSelectionStyles(nodeTwo);
                const { data, ...nodeToAdd } = {
                  ...nodeTwo,
                  ...nodeTwoSelectionStyles,
                };
                const size = getSize(nodeSizingType, data);
                items[nodeTwoId] = { ...nodeToAdd, size };
                added2 = true;
                nodesAlreadyStyled[nodeTwoId] = nodeTwoId;
              }
            }
          } else if (
            canAddNode(
              nodeTwo,
              isRegionsFilterEmpty,
              isConstituenciesEmpty,
              constitencies,
              nodeFilters,
              hiddenNodes,
            )
          ) {
            const nodeTwoSelectionStyles = GetNodeSelectionStyles(nodeTwo);
            const { data, ...nodeToAdd } = {
              ...nodeTwo,
              ...nodeTwoSelectionStyles,
            };
            const size = getSize(nodeSizingType, data);
            items[nodeTwoId] = { ...nodeToAdd, size };
            nodesAlreadyStyled[nodeTwoId] = nodeTwoId;
            added2 = true;
          }

          if (added1 && added2) {
            const { data, ...newGraphLink } = {
              ...graphLink,
              color: graphLink.activeColor,
            };
            items[linkId] = newGraphLink;
          }
        } else {
          let added1 = false;
          let added2 = false;
          // check if node already added
          if (items[nodeOneId] !== undefined) {
            added1 = true;
          } else if (nodeFiltersEnabled) {
            if (nodeFilterType === HIGHLIGHTED_STATE) {
              if (
                canAddNode(
                  nodeOne,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                ) &&
                !nodeTypeFilterOut.includes(nodeOne.data.nodeType)
              ) {
                let newNode = {};
                if (nodeOptions.includes(nodeOne.data.nodeType)) {
                  const nodeOneSelectionStyles =
                    GetNodeSelectionStyles(nodeOne);
                  newNode = { ...nodeOne, ...nodeOneSelectionStyles };
                } else {
                  newNode = nodeOne;
                }
                const { data, ...nodeToAdd } = newNode;
                const size = getSize(nodeSizingType, data);
                items[nodeOneId] = { ...nodeToAdd, size };
                added1 = true;
              }
            } else if (nodeFilterType === SELECTED_STATE) {
              if (
                nodeOptions.includes(nodeOne.data.nodeType) &&
                canAddNode(
                  nodeOne,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                )
              ) {
                const nodeOneSelectionStyles = GetNodeSelectionStyles(nodeOne);
                const { data, ...nodeToAdd } = {
                  ...nodeOne,
                  ...nodeOneSelectionStyles,
                };
                const size = getSize(nodeSizingType, data);
                items[nodeOneId] = { ...nodeToAdd, size };
                added1 = true;
              } else {
              }
            } else {
              if (
                canAddNode(
                  nodeOne,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                ) &&
                !nodeTypeFilterOut.includes(nodeOne.data.nodeType)
              ) {
                const { data, ...nodeToAdd } = nodeOne;
                const size = getSize(nodeSizingType, data);
                items[nodeOneId] = { ...nodeToAdd, size };
                added1 = true;
              }
            }
          } else if (
            canAddNode(
              nodeOne,
              isRegionsFilterEmpty,
              isConstituenciesEmpty,
              constitencies,
              nodeFilters,
              hiddenNodes,
            )
          ) {
            const { data, ...nodeToAdd } = nodeOne;
            const size = getSize(nodeSizingType, data);
            items[nodeOneId] = { ...nodeToAdd, size };
            added1 = true;
          }

          if (items[nodeTwoId] !== undefined) {
            added2 = true;
          } else if (nodeFiltersEnabled) {
            if (nodeFilterType === HIGHLIGHTED_STATE) {
              if (
                canAddNode(
                  nodeTwo,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                ) &&
                !nodeTypeFilterOut.includes(nodeTwo.data.nodeType)
              ) {
                let newNode = {};
                if (nodeOptions.includes(nodeTwo.data.nodeType)) {
                  const nodeTwoSelectionStyles =
                    GetNodeSelectionStyles(nodeTwo);
                  newNode = { ...nodeTwo, ...nodeTwoSelectionStyles };
                } else {
                  newNode = nodeTwo;
                }
                const { data, ...nodeToAdd } = newNode;
                const size = getSize(nodeSizingType, data);
                items[nodeTwoId] = { ...nodeToAdd, size };
                added2 = true;
              }
            } else if (nodeFilterType === SELECTED_STATE) {
              if (
                nodeOptions.includes(nodeTwo.data.nodeType) &&
                canAddNode(
                  nodeTwo,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                )
              ) {
                const nodeTwoSelectionStyles = GetNodeSelectionStyles(nodeTwo);
                const { data, ...nodeToAdd } = {
                  ...nodeTwo,
                  ...nodeTwoSelectionStyles,
                };
                const size = getSize(nodeSizingType, data);
                items[nodeTwoId] = { ...nodeToAdd, size };
                added2 = true;
              } else {
              }
            } else {
              if (
                canAddNode(
                  nodeTwo,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                ) &&
                !nodeTypeFilterOut.includes(nodeTwo.data.nodeType)
              ) {
                const { data, ...nodeToAdd } = nodeTwo;
                const size = getSize(nodeSizingType, data);
                items[nodeTwoId] = { ...nodeToAdd, size };
                added2 = true;
              }
            }
          } else if (
            canAddNode(
              nodeTwo,
              isRegionsFilterEmpty,
              isConstituenciesEmpty,
              constitencies,
              nodeFilters,
              hiddenNodes,
            )
          ) {
            const { data, ...nodeToAdd } = nodeTwo;
            const size = getSize(nodeSizingType, data);
            items[nodeTwoId] = { ...nodeToAdd, size };
            added2 = true;
          }

          if (added1 && added2) {
            const { data, ...newGraphLink } = graphLink;
            items[linkId] = newGraphLink;
          }
        }
      } else {
        // filterType is selected state
        const isIncluded = isEdgeIncluded(graphLink, edgeFilters, timeFilters);
        if (isIncluded) {
          const nodeOne = nodes[nodeOneId]?.node;
          const nodeTwo = nodes[nodeTwoId]?.node;
          let added1 = false;
          let added2 = false;
          if (items[nodeOneId] !== undefined) {
            added1 = true;
          } else if (nodeFiltersEnabled) {
            if (nodeFilterType === HIGHLIGHTED_STATE) {
              if (
                canAddNode(
                  nodeOne,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                ) &&
                !nodeTypeFilterOut.includes(nodeOne.data.nodeType)
              ) {
                let newNode = {};
                if (nodeOptions.includes(nodeOne.data.nodeType)) {
                  const nodeOneSelectionStyles =
                    GetNodeSelectionStyles(nodeOne);
                  newNode = { ...nodeOne, ...nodeOneSelectionStyles };
                } else {
                  newNode = nodeOne;
                }
                const { data, ...nodeToAdd } = newNode;
                const size = getSize(nodeSizingType, data);
                items[nodeOneId] = { ...nodeToAdd, size };
                added1 = true;
              }
            } else if (nodeFilterType === SELECTED_STATE) {
              if (
                nodeOptions.includes(nodeOne.data.nodeType) &&
                canAddNode(
                  nodeOne,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                )
              ) {
                const nodeOneSelectionStyles = GetNodeSelectionStyles(nodeOne);
                const { data, ...nodeToAdd } = {
                  ...nodeOne,
                  ...nodeOneSelectionStyles,
                };
                const size = getSize(nodeSizingType, data);
                items[nodeOneId] = { ...nodeToAdd, size };
                added1 = true;
              } else {
              }
            } else {
              if (
                canAddNode(
                  nodeOne,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                ) &&
                !nodeTypeFilterOut.includes(nodeOne.data.nodeType)
              ) {
                const { data, ...nodeToAdd } = nodeOne;
                const size = getSize(nodeSizingType, data);
                items[nodeOneId] = { ...nodeToAdd, size };
                added1 = true;
              }
            }
          } else if (
            canAddNode(
              nodeOne,
              isRegionsFilterEmpty,
              isConstituenciesEmpty,
              constitencies,
              nodeFilters,
              hiddenNodes,
            )
          ) {
            const nodeOneSelectionStyles = GetNodeSelectionStyles(nodeOne);
            const { data, ...nodeToAdd } = {
              ...nodeOne,
              ...nodeOneSelectionStyles,
            };
            const size = getSize(nodeSizingType, data);
            items[nodeOneId] = { ...nodeToAdd, size };
            added1 = true;
          }

          if (items[nodeTwoId] !== undefined) {
            added2 = true;
          } else if (nodeFiltersEnabled) {
            if (nodeFilterType === HIGHLIGHTED_STATE) {
              if (
                canAddNode(
                  nodeTwo,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                ) &&
                !nodeTypeFilterOut.includes(nodeTwo.data.nodeType)
              ) {
                let newNode = {};
                if (nodeOptions.includes(nodeTwo.data.nodeType)) {
                  const nodeTwoSelectionStyles =
                    GetNodeSelectionStyles(nodeTwo);
                  newNode = { ...nodeTwo, ...nodeTwoSelectionStyles };
                } else {
                  newNode = nodeTwo;
                }
                const { data, ...nodeToAdd } = newNode;
                const size = getSize(nodeSizingType, data);
                items[nodeTwoId] = { ...nodeToAdd, size };
                added2 = true;
              }
            } else if (nodeFilterType === SELECTED_STATE) {
              if (
                nodeOptions.includes(nodeTwo.data.nodeType) &&
                canAddNode(
                  nodeTwo,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                )
              ) {
                const nodeTwoSelectionStyles = GetNodeSelectionStyles(nodeTwo);
                const { data, ...nodeToAdd } = {
                  ...nodeTwo,
                  ...nodeTwoSelectionStyles,
                };

                const size = getSize(nodeSizingType, data);
                items[nodeTwoId] = { ...nodeToAdd, size };
                added2 = true;
              } else {
              }
            } else {
              if (
                canAddNode(
                  nodeTwo,
                  isRegionsFilterEmpty,
                  isConstituenciesEmpty,
                  constitencies,
                  nodeFilters,
                  hiddenNodes,
                ) &&
                !nodeTypeFilterOut.includes(nodeTwo.data.nodeType)
              ) {
                const { data, ...nodeToAdd } = nodeTwo;
                const size = getSize(nodeSizingType, data);
                items[nodeTwoId] = { ...nodeToAdd, size };
                added2 = true;
              }
            }
          } else if (
            canAddNode(
              nodeTwo,
              isRegionsFilterEmpty,
              isConstituenciesEmpty,
              constitencies,
              nodeFilters,
              hiddenNodes,
            )
          ) {
            const nodeTwoSelectionStyles = GetNodeSelectionStyles(nodeTwo);
            const { data, ...nodeToAdd } = {
              ...nodeTwo,
              ...nodeTwoSelectionStyles,
            };
            const size = getSize(nodeSizingType, data);
            items[nodeTwoId] = { ...nodeToAdd, size };
            added2 = true;
          }
          if (added1 && added2) {
            const { data, ...newGraphLink } = {
              ...graphLink,
              color: graphLink.activeColor,
            };
            items[linkId] = newGraphLink;
          }
        }
      }
    }
  }

  for (const contextId in contexts) {
    const context = contexts[contextId];
    if (
      context !== undefined &&
      context?.value?.id === context?.value?.metroRegion
    ) {
      context?.nodeIds.forEach((x) => {
        const node = nodes[x]?.node;
        console.log(node, x, items);
        if (node !== undefined && items[x] === undefined) {
          if (
            canAddNode(
              node,
              isRegionsFilterEmpty,
              isConstituenciesEmpty,
              constitencies,
              nodeFilters,
              hiddenNodes,
            )
          ) {
            if (!nodeFiltersEnabled) {
              const { data, ...nodeToAdd } = node;
              const size = getSize(nodeSizingType, data);
              items[x] = { ...nodeToAdd, size };
            } else {
              if (nodeFilterType === HIGHLIGHTED_STATE) {
                if (!nodeTypeFilterOut.includes(node.data.nodeType)) {
                  let newNode = node;
                  if (nodeOptions.includes(node.data.nodeType)) {
                    const nodeOneSelectionStyles = GetNodeSelectionStyles(node);
                    newNode = { ...node, ...nodeOneSelectionStyles };
                  }

                  const { data, ...nodeToAdd } = newNode;
                  const size = getSize(nodeSizingType, data);
                  items[x] = { ...nodeToAdd, size };
                }
              } else if (nodeFilterType === SELECTED_STATE) {
                if (
                  nodeOptions.includes(node.data.nodeType) &&
                  canAddNode(
                    node,
                    isRegionsFilterEmpty,
                    isConstituenciesEmpty,
                    constitencies,
                    nodeFilters,
                    hiddenNodes,
                  )
                ) {
                  const nodeOneSelectionStyles = GetNodeSelectionStyles(node);
                  const { data, ...nodeToAdd } = {
                    ...node,
                    ...nodeOneSelectionStyles,
                  };
                  const size = getSize(nodeSizingType, data);
                  items[x] = { ...nodeToAdd, size };
                }
              }
            }
          }
        }
      });
    }
  }

  p2pIds?.forEach((p2pContext) => {
    p2pContext?.nodeIds?.forEach((nodeId) => {
      if (items[nodeId] === undefined) {
        const node = nodes[nodeId]?.node;
        if (nodeFiltersEnabled) {
          if (nodeFilterType === HIGHLIGHTED_STATE) {
            if (
              canAddNode(
                node,
                isRegionsFilterEmpty,
                isConstituenciesEmpty,
                constitencies,
                nodeFilters,
                hiddenNodes,
              ) &&
              !nodeTypeFilterOut.includes(node.data.nodeType)
            ) {
              let newNode = {};
              if (nodeOptions.includes(node.data.nodeType)) {
                const nodeSelectionStyles = GetNodeSelectionStyles(node);
                newNode = { ...node, ...nodeSelectionStyles };
              } else {
                newNode = node;
              }
              const { data, ...nodeToAdd } = newNode;
              const size = getSize(nodeSizingType, data);
              items[nodeId] = { ...nodeToAdd, size };
            }
          } else if (nodeFilterType === SELECTED_STATE) {
            if (
              nodeOptions.includes(node.data.nodeType) &&
              canAddNode(
                node,
                isRegionsFilterEmpty,
                isConstituenciesEmpty,
                constitencies,
                nodeFilters,
                hiddenNodes,
              )
            ) {
              const nodeSelectionStyles = GetNodeSelectionStyles(node);
              const { data, ...nodeTwoAdd } = {
                ...node,
                ...nodeSelectionStyles,
              };
              const size = getSize(nodeSizingType, data);
              items[nodeId] = { ...nodeTwoAdd, size };
            } else {
            }
          } else {
            if (
              canAddNode(
                node,
                isRegionsFilterEmpty,
                isConstituenciesEmpty,
                constitencies,
                nodeFilters,
                hiddenNodes,
              ) &&
              !nodeTypeFilterOut.includes(node.data.nodeType)
            ) {
              const nodeSelectionStyles = GetNodeSelectionStyles(node);
              const { data, ...nodeTwoAdd } = {
                ...node,
                ...nodeSelectionStyles,
              };
              const size = getSize(nodeSizingType, data);
              items[nodeId] = { ...nodeTwoAdd, size };
            }
          }
        } else if (
          canAddNode(
            node,
            isRegionsFilterEmpty,
            isConstituenciesEmpty,
            constitencies,
            nodeFilters,
            hiddenNodes,
          )
        ) {
          const { data, ...nodeTwoAdd } = node;
          const size = getSize(nodeSizingType, data);
          items[nodeId] = { ...nodeTwoAdd, size };
        }
      }
    });
  });

  if (shortestPathNodeid) {
    const pathNode = nodes[shortestPathNodeid]?.node;
    if (pathNode === null || pathNode === undefined) {
    } else {
      const newNode = {
        ...pathNode,
        glyphs: [
          {
            size: 1,
            label: {
              text: '-',
              color: '#fff',
            },
          },
        ],
      };
      items[shortestPathNodeid] = newNode;
    }
  }

  // const length = Object.keys(items).length;
  // console.log('nodes-drawn', length);
  return items;
};

export const isEdgeIncluded = (
  graphLink,
  edgeFilters,
  timefilters,
  isRemoval = false,
) => {
  const isIncluded = edgeFilters.includes(graphLink?.data?.connection);
  if (isIncluded) {
    if (graphLink?.data?.connectionType === RELATIONSHIP_TYPE_FAMILY) {
      const { familyOptionsTimeFilter } = timefilters;
      return getTimeFilterIsIncluded(
        familyOptionsTimeFilter.start,
        familyOptionsTimeFilter.end,
        graphLink,
        isRemoval,
      );
    }
    if (graphLink?.data?.connectionType === RELATIONSHIP_TYPE_PERSONAL) {
      const { professionalOptionsTimeFilter } = timefilters;
      return getTimeFilterIsIncluded(
        professionalOptionsTimeFilter.start,
        professionalOptionsTimeFilter.end,
        graphLink,
        isRemoval,
      );
    }
    if (graphLink?.data?.connectionType === RELATIONSHIP_TYPE_ORGANIZATIONAL) {
      const { organizationalOptionsTimeFilter } = timefilters;
      return getTimeFilterIsIncluded(
        organizationalOptionsTimeFilter.start,
        organizationalOptionsTimeFilter.end,
        graphLink,
        isRemoval,
      );
    }
    if (graphLink?.data?.connectionType === RELATIONSHIP_TYPE_CHARITABLE) {
      const { charitableOptionsTimeFilter } = timefilters;
      return getTimeFilterIsIncluded(
        charitableOptionsTimeFilter.start,
        charitableOptionsTimeFilter.end,
        graphLink,
        isRemoval,
      );
    }
    if (graphLink?.data?.connectionType === RELATIONSHIP_TYPE_EDUCATION) {
      const { educationOptionsTimeFilter } = timefilters;
      return getTimeFilterIsIncluded(
        educationOptionsTimeFilter.start,
        educationOptionsTimeFilter.end,
        graphLink,
        isRemoval,
      );
    }
    if (graphLink?.data?.connectionType === RELATIONSHIP_TYPE_ENGAGEMENT) {
      const { engagementOptionsTimeFilter } = timefilters;
      return getTimeFilterIsIncluded(
        engagementOptionsTimeFilter.start,
        engagementOptionsTimeFilter.end,
        graphLink,
        isRemoval,
      );
    }
  }
  return false;
};

export const getTimeFilterIsIncluded = (start, end, graphLink, isRemoval) => {
  if (start === null && end === null) {
    return true;
  }
  if (!isRemoval) {
    if (start !== null) {
      const startdate = graphLink?.data?.startdate;
      if (startdate === undefined) {
        return false;
      }
      const graphStartDate = new Date(startdate);
      if (graphStartDate < new Date(start)) {
        return false;
      }
    }
    if (end !== null) {
      const enddate = graphLink?.data?.enddate;
      if (enddate === undefined) {
        return false;
      }
      const graphEndDate = new Date(enddate);
      if (graphEndDate > new Date(end)) {
        return false;
      }
    }
  } else {
    if (start !== null) {
      const startdate = graphLink?.data?.startdate;
      if (startdate !== undefined) {
        const graphStartDate = new Date(startdate);
        if (graphStartDate < new Date(start)) {
          return false;
        }
      }
    }
    if (end !== null) {
      const enddate = graphLink?.data?.enddate;
      if (enddate !== undefined) {
        const graphEndDate = new Date(enddate);
        if (graphEndDate > new Date(end)) {
          return false;
        }
      }
    }
  }
  // if none apply return true;
  return true;
};
