import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectContexts } from 'store/graph/selectors';
import ContextInputItem from './input-item';
import styles from './context-input.module.css';

const ContextInputs = () => {
  const contexts = useSelector(selectContexts);
  return (
    <>
      {contexts && (
        <div className={styles.wrapper}>
          {contexts.map((context) => (
            <ContextInputItem
              key={context.id}
              context={context}
              contextlength={contexts.length}
            />
          ))}
        </div>
      )}
    </>
  );
};

ContextInputs.propTypes = {
  contexts: PropTypes.array,
};

export default ContextInputs;
